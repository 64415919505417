import { DEFAULT_POPUP_ERROR_MESSAGE_LIST } from "@/components/popup/error/PopupError";
import { PopupMessageList } from "@/components/popup/message/PopupMessage";
import {
  EMPTY_OVERLAY_FEEDBACK_COUNTS,
  OverlayFeedbackCounts,
  OverlayFeedbackType,
} from "@/types/feedback-types";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";

interface GlobalStoreInterface {
  socket: WebSocket;
  stomp: Stomp.Client;
  isShowingPopupError: boolean;
  popupErrorMessageList: PopupMessageList;
  overlayFeedbackActionCounts: OverlayFeedbackCounts;
  fullScreen: boolean;
  portalMap: Map<string, React.MutableRefObject<HTMLElement>>;
  globalActions: {
    initSocket: (memberId: number) => void;
    showPopupError: (messageList?: PopupMessageList) => void;
    resetPopupError: () => void;
    updateOverlayFeedbackActionCounts: (
      type: OverlayFeedbackType,
      count: number,
    ) => void;
    toggleFullScreen: () => void;
    addPortal: (
      id: string,
      portalRef: React.MutableRefObject<HTMLElement>,
    ) => void;
  };
}

const useGlobalStore = create<GlobalStoreInterface>((set, get) => ({
  socket: null as unknown as WebSocket,
  stomp: null as unknown as Stomp.Client,
  isShowingPopupError: false,
  popupErrorMessageList: DEFAULT_POPUP_ERROR_MESSAGE_LIST,
  overlayFeedbackActionCounts: EMPTY_OVERLAY_FEEDBACK_COUNTS,
  fullScreen: false,
  portalMap: new Map(),
  globalActions: {
    initSocket: (memberId) => {
      if (get().socket === null || get().stomp === null) {
        const socket = new SockJS("/ws", null, {
          transports: ["websocket"],
        });
        const stomp = Stomp.over(socket);
        stomp.debug = () => {};

        stomp.connect(
          {
            "x-hng-member-id": memberId,
          },
          () => {},
        );

        return set(() => ({ socket, stomp }));
      }
    },
    showPopupError: (messageList) =>
      set(() => ({
        isShowingPopupError: true,
        popupErrorMessageList: messageList || DEFAULT_POPUP_ERROR_MESSAGE_LIST,
      })),
    resetPopupError: () =>
      set(() => ({
        isShowingPopupError: false,
      })),
    updateOverlayFeedbackActionCounts: (type, count) => {
      const updatedCounts = { ...EMPTY_OVERLAY_FEEDBACK_COUNTS };
      updatedCounts[type] = count;

      set(() => ({
        overlayFeedbackActionCounts: updatedCounts,
      }));
    },
    toggleFullScreen: () => {
      set((state) => ({
        fullScreen: !state.fullScreen,
      }));
    },
    addPortal: (id, portalRef) => {
      const newMap = new Map(get().portalMap);
      newMap.set(id, portalRef);

      set(() => ({
        portalMap: newMap,
      }));
    },
  },
}));

export default useGlobalStore;
export const useGlobalActions = () =>
  useGlobalStore((state) => state.globalActions);
export const useStomp = () => useGlobalStore((state) => state.stomp);
export const usePopupError = () =>
  useGlobalStore(
    useShallow((state) => ({
      isShowingPopupError: state.isShowingPopupError,
      popupErrorMessageList: state.popupErrorMessageList,
    })),
  );
export const useOverlayFeedbackActionCounts = () =>
  useGlobalStore((state) => state.overlayFeedbackActionCounts);
export const useFullScreen = () => useGlobalStore((state) => state.fullScreen);
export const usePortalMap = () => useGlobalStore((state) => state.portalMap);
