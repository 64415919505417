import Cookies from "cookies";
import Cookie from "js-cookie";
import { add } from "date-fns";
import { HengeAuthTokens } from "@/classes/auth/Auth";
import { IncomingMessage, ServerResponse } from "http";

export function saveRefreshTokenSSR(
  req: IncomingMessage & {
    cookies: Partial<{
      [key: string]: string;
    }>;
  },
  res: ServerResponse<IncomingMessage>,
  refreshToken: HengeAuthTokens["refreshToken"],
) {
  const now: Date = new Date();
  const expiresDate: Date = add(now, { days: 30 });

  const cookies = new Cookies(req, res);
  cookies.set("x-hng-refresh-token", refreshToken.value, {
    expires: expiresDate,
    httpOnly: true,
    sameSite: "lax",
  });
}

export function terminateRefreshTokenSSR(
  req: IncomingMessage & {
    cookies: Partial<{
      [key: string]: string;
    }>;
  },
  res: ServerResponse<IncomingMessage>,
) {
  const cookies = new Cookies(req, res);
  cookies.set("x-hng-refresh-token", null);
}

export function terminateRefreshTokenCSR() {
  Cookie.remove("x-hng-refresh-token");
}
