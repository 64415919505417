import Header from "@/components/layout/header/Header";
import Main from "@/components/layout/main/Main";
// import Footer from "@/components/layout/footer/Footer";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Header></Header>
      <Main>{children}</Main>
      {/*<Footer></Footer>*/}
    </>
  );
};

export default Layout;
