import React, { ErrorInfo } from "react";
import Error from "@/pages/_error";

interface Props {
  children?: React.ReactNode;
  fallbackComponent?: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallbackComponent) {
        return this.props.fallbackComponent;
      }

      return (
        <Error />
        // <div
        //   css={css({
        //     display: "flex",
        //     flexFlow: "column nowrap",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     width: "100%",
        //     height: "100%",
        //   })}
        // >
        //   <HUI.Text kind={HENGE.TEXT_KIND.heading2}>Error occurred!</HUI.Text>
        //   <HUI.Text kind={HENGE.TEXT_KIND.small}>
        //     {this.state.err && this.state.err.toString()}
        //   </HUI.Text>
        //   <br />
        //   <HUI.Text kind={HENGE.TEXT_KIND.small}>
        //     {this.state.errorInfo && this.state.errorInfo.componentStack}
        //   </HUI.Text>
        // </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
