import { create, StoreApi, UseBoundStore } from "zustand";

export type SessionCurrentLocation = {
  gaiaId: number | null;
  hengeId: number | null;
};

export interface SocketSessionStoreInterface {
  socketSessionId: string | null;
  timestamp: number;
  currentLocation: SessionCurrentLocation;
  socketSessionStoreActions: {
    init: (socketSessionId: string) => void;
    moveTo: (currentLocation: SessionCurrentLocation) => void;
  };
}

export function createSocketSessionStore() {
  return create<SocketSessionStoreInterface>((set) => ({
    socketSessionId: null,
    timestamp: new Date().getTime(),
    currentLocation: {
      gaiaId: null,
      hengeId: null,
    },
    socketSessionStoreActions: {
      init: (socketSessionId) => {
        set({ socketSessionId });
      },
      moveTo: (currentLocation) => {
        set({ currentLocation });
      },
    },
  }));
}

export function useSocketSessionStore<T = SocketSessionStoreInterface>(
  socketSessionStore: UseBoundStore<StoreApi<SocketSessionStoreInterface>>,
  selector: (state: SocketSessionStoreInterface) => T = (state) =>
    state as unknown as T,
) {
  return socketSessionStore(selector);
}
