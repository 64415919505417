export const CURSOR_ENUM = {
  default: "default",
  pointer: "pointer",
  grab: "grab",
  grabbing: "grabbing",
  move: "move",
  ns_resize: "ns-resize",
  zoom_in: "zoom-in",
  zoom_out: "zoom-out",
  wait: "wait",
  help: "help",
};
export type CursorType = (typeof CURSOR_ENUM)[keyof typeof CURSOR_ENUM];

// export function changeCursorType(cursor: CursorType): void {
//   if (!document.body.classList.contains(cursor)) {
//     document.body.classList.remove(...Object.values(CURSOR_ENUM));
//     document.body.classList.add(cursor);
//   }
// }

export function changeCanvasCursorType(
  canvas: HTMLCanvasElement,
  cursor: CursorType,
): void {
  canvas.style.cursor = cursor;
}
