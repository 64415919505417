import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";

interface PortalRootProps {
  id: string;
  className?: string;
  zIndex?: number;
}

export const PortalRoot = ({
  id,
  className,
  zIndex = 3000,
}: PortalRootProps) => {
  const ref = useRef<HTMLDivElement>(null!);

  const [isCSR, setCSR] = useState<boolean>(false);

  useEffect(() => {
    setCSR(true);
  }, []);

  if (!isCSR) {
    return null;
  } else {
    return (
      <div
        ref={ref}
        id={id}
        className={className}
        css={css({
          pointerEvents: "none",
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex,
        })}
      />
    );
  }
};
