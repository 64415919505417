import {
  useMember,
  useMemberCursorStore,
  useMemberNextAuthSession,
  useMemberStoreActions,
} from "@/stores/zustand/member/useMemberStore";
import {
  useCursorStoreActions,
  useInitCursorStore,
} from "@/stores/zustand/useCursorStore";
import { useGlobalActions } from "@/stores/zustand/useGlobalStore";
import HUI from "@henge-inc/ui/v1_2";
import { useSession } from "next-auth/react";
import { useEffect } from "react";

export const GloballyNextAuthSession = () => {
  const nextAuthSession = useSession();

  const member = useMember();
  const {
    updateMember,
    updateNextAuthSession,
    initAuthTokens,
    updateAccessToken,
  } = useMemberStoreActions();

  const memberNextAuthSession = useMemberNextAuthSession();

  const cursorStore = useMemberCursorStore();
  const initCursorStore = useInitCursorStore(cursorStore);
  const { changeColour } = useCursorStoreActions(cursorStore);

  const { initSocket } = useGlobalActions();

  useEffect(() => {
    updateNextAuthSession(nextAuthSession);
  }, [nextAuthSession]);

  useEffect(() => {
    window.__HENGE__ = {
      member_uid: member?.metadata?.uid,
      insider: member?.metadata?.uid?.startsWith("__HENGE__"),
      OAuthStore: undefined,
    };
  }, [member]);

  useEffect(() => {
    if (
      memberNextAuthSession.status === "authenticated" &&
      memberNextAuthSession.data?.user?.metadata
    ) {
      updateMember({ metadata: memberNextAuthSession.data.user.metadata });
    }
  }, [memberNextAuthSession]);

  useEffect(() => {
    if (
      memberNextAuthSession.status === "authenticated" &&
      memberNextAuthSession.data?.user?.authTokens
    ) {
      initAuthTokens(memberNextAuthSession.data.user.authTokens);
    }
  }, [memberNextAuthSession]);

  useEffect(() => {
    if (
      memberNextAuthSession.status === "authenticated" &&
      memberNextAuthSession.data?.user?.authTokens?.accessToken
    ) {
      updateAccessToken(memberNextAuthSession.data.user.authTokens.accessToken);
    }
  }, [memberNextAuthSession]);

  useEffect(() => {
    if (!member) return;

    initCursorStore({
      abbrName: HUI.abbreviateName(
        member.metadata.familyName,
        member.metadata.givenName,
      ),
      personalColour: member.metadata.personalColour,
    });
  }, [member]);

  useEffect(() => {
    if (!member?.metadata?.personalColour) return;

    changeColour(member.metadata.personalColour);
  }, [member?.metadata?.personalColour]);

  useEffect(() => {
    const mousemoveHandler = (evt: MouseEvent) => {
      cursorStore.getState().cursorStoreActions.updateMouse(evt);
    };

    window.addEventListener("mousemove", mousemoveHandler);
    window.addEventListener("dragover", mousemoveHandler);

    return () => {
      window.removeEventListener("mousemove", mousemoveHandler);
      window.removeEventListener("dragover", mousemoveHandler);
    };
  }, [nextAuthSession]);

  useEffect(() => {
    if (memberNextAuthSession.data?.user?.metadata?.id) {
      initSocket(memberNextAuthSession.data.user.metadata.id);
    }
  }, [memberNextAuthSession]);

  return <></>;
};
