import styled from "@emotion/styled";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";

export interface GaiaItemProps extends React.HTMLAttributes<HTMLDivElement> {
  gridRuleset: HENGE.GridRuleType[];
}

const GridItem = ({ children, className, gridRuleset }: GaiaItemProps) => {
  return (
    <StyledGridItem
      gridRuleset={HUI.parseGridRuleset(gridRuleset)}
      className={className}
    >
      {children}
    </StyledGridItem>
  );
};

export default GridItem;

const StyledGridItem = styled.div<{ gridRuleset: string }>`
  ${(props) => props.gridRuleset};

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;
