import Cookie from "js-cookie";

export type OverlayFeedbackCookie = {
  iat: number;
  remind: number;
  submit: boolean;
} & OverlayFeedbackCounts;

export type OverlayFeedbackCounts = { [K in OverlayFeedbackType]: number };

export const EMPTY_OVERLAY_FEEDBACK_COUNTS: OverlayFeedbackCounts = {
  Gaia: 0,
  HengeOnGaia: 0,
  BC: 0,
  TuningShop: 0,
  Document: 0,
} as const;

export const EMPTY_OVERLAY_FEEDBACK_COOKIE: OverlayFeedbackCookie = {
  iat: 0,
  remind: 0,
  submit: false,
  ...EMPTY_OVERLAY_FEEDBACK_COUNTS,
} as const;

export const TRIGGER_OVERLAY_FEEDBACK_COUNTS: OverlayFeedbackCounts = {
  Gaia: 30,
  HengeOnGaia: 50,
  BC: 30,
  TuningShop: 10,
  Document: 5,
} as const;

export type OverlayFeedbackCookieStatus = "ready" | "later" | "submit";

export type OverlayFeedbackType =
  | "Gaia"
  | "HengeOnGaia"
  | "BC"
  | "TuningShop"
  | "Document";

export function resetOverlayFeedbackCookie() {
  Cookie.set(
    "x-hng-overlay-feedback",
    JSON.stringify(EMPTY_OVERLAY_FEEDBACK_COOKIE),
    {
      expires: 30,
      secure: true,
      sameSite: "Lax",
    },
  );
}

const REMIND_TIMESTAMP_OFFSET = 1000 * 60 * 60 * 24 * 7;

export function setOverlayFeedbackRemind() {
  const feedbackCookieStr = Cookie.get("x-hng-overlay-feedback");
  if (!feedbackCookieStr) return;
  const feedbackCookie: OverlayFeedbackCookie = JSON.parse(feedbackCookieStr);

  const updatedCookie: OverlayFeedbackCookie = {
    ...feedbackCookie,
    remind: new Date().getTime() + REMIND_TIMESTAMP_OFFSET,
  };

  Cookie.set("x-hng-overlay-feedback", JSON.stringify(updatedCookie), {
    expires: 30,
    secure: true,
    sameSite: "Lax",
  });
}

export function setOverlayFeedbackSubmit() {
  const feedbackCookieStr = Cookie.get("x-hng-overlay-feedback");
  if (!feedbackCookieStr) return;
  const feedbackCookie: OverlayFeedbackCookie = JSON.parse(feedbackCookieStr);

  const updatedCookie: OverlayFeedbackCookie = {
    ...feedbackCookie,
    submit: true,
  };

  Cookie.set("x-hng-overlay-feedback", JSON.stringify(updatedCookie), {
    expires: 30,
    secure: true,
    sameSite: "Lax",
  });
}

export function addOverlayFeedbackCookie(counts: OverlayFeedbackCounts) {
  const feedbackCookieStr = Cookie.get("x-hng-overlay-feedback");
  if (!feedbackCookieStr) return;
  const feedbackCookie: OverlayFeedbackCookie = JSON.parse(feedbackCookieStr);

  const updatedCookie: OverlayFeedbackCookie = {
    ...feedbackCookie,
    iat: new Date().getTime(),
  };
  Object.entries(counts).forEach((item) => {
    const feedbackType: OverlayFeedbackType = item[0] as OverlayFeedbackType;
    const count: number = item[1];
    updatedCookie[feedbackType] += count;
  });

  Cookie.set("x-hng-overlay-feedback", JSON.stringify(updatedCookie), {
    expires: 30,
    secure: true,
    sameSite: "Lax",
  });
}
