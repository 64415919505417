import { PopupLayout, PopupLayoutProps } from "@/components/popup/PopupLayout";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";

interface PopupMessageTextProps {
  text: string;
  kind?: HENGE.TextKindType;
  weight?: HENGE.TextWeightType;
  colour?: HENGE.TextColourType;
  align?: HENGE.TextAlignType;
}

export type PopupMessageList = PopupMessageTextProps[];

interface PopupMessageProps extends PopupLayoutProps {
  messageList?: PopupMessageList;
  children?: React.ReactNode;
}

export const PopupMessage = (props: PopupMessageProps) => {
  return (
    <PopupLayout {...props}>
      <div className="flex flex-col flex-nowrap justify-center items-center gap-1 w-full h-full">
        {props.messageList &&
          props.messageList.map((message, index) => (
            <HUI.Text
              key={`popup-message-${index}`}
              kind={message.kind || HENGE.TEXT_KIND.body2}
              weight={message.weight || HENGE.TEXT_WEIGHT.medium}
              colour={
                message.colour || HENGE.SEMANTIC_TEXT_COLOUR["text-secondary"]
              }
              align={message.align || HENGE.TEXT_ALIGN.center}
            >
              {message.text}
            </HUI.Text>
          ))}
        {props.children}
      </div>
    </PopupLayout>
  );
};
