import React from "react";
import { PopupLayoutHandleProps } from "@/components/popup/PopupLayout";
import {
  PopupMessage,
  PopupMessageList,
} from "@/components/popup/message/PopupMessage";

export const POPUP_ERROR_FIRST_SHOWING_SECONDS = 4;

export const DEFAULT_POPUP_ERROR_MESSAGE_LIST = [
  {
    text: "Something went wrong.",
  },
  {
    text: "Please try again in few minutes.",
  },
];

interface PopupErrorProps extends PopupLayoutHandleProps {
  messageList: PopupMessageList;
}

export const PopupError = ({
  handle,
  resetHandler,
  messageList,
}: PopupErrorProps) => {
  return (
    <PopupMessage
      handle={handle}
      resetHandler={resetHandler}
      firstShowingSeconds={POPUP_ERROR_FIRST_SHOWING_SECONDS}
      messageList={messageList}
    />
  );
};
