import { HengeAuthTokens } from "@/classes/auth/Auth";
import { GaiaStoreInterface } from "@/engine/viewer/core/useGaiaStore";
import useGaiaStoreContainer from "@/engine/viewer/core/useGaiaStoreContainer";
import { GA4_CUSTOM_TRIGGER } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import useGlobalStore from "@/stores/zustand/useGlobalStore";
import { UpdateHengeRequest } from "@/types/api/henge/request/henge-request";
import { GaiaData, HengeData } from "@/types/data-types";
import { GaiaMemberRoleType } from "@/types/enums";
import { fetchUpdateHengeAPI } from "@/utils/api/fetchWithHengeAuthAPIs";
import { CURSOR_ENUM, changeCanvasCursorType } from "@/utils/commonUtil";
import {
  adjustPositionAfterSized,
  // calcBoundingBox,
  calcRotationByWheelDelta,
  calcSizeByWheelDelta,
  // checkHengePositionOverTrashCan,
  checkHengePositionWithinGaiaGrid,
  clampPositionWithinGaia,
  clampSizeWithinGaia,
  gsapHengePosition,
  gsapHengeRotation,
  gsapHengeSize,
  // initHengeUserData,
  snapPositionToGrid,
  snapSizeToGrid,
} from "@/utils/hengeUtil";
import { makeMaterialOpaque } from "@/utils/threeUtil";
import { Camera, ThreeEvent } from "@react-three/fiber";
import { NextRouter } from "next/router";
import * as THREE from "three";
import { StoreApi, UseBoundStore } from "zustand";

export const PointerOverEventType = "PointerOver" as const;
export type PointerOverEvent = {
  type: typeof PointerOverEventType;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const PointerOutEventType = "PointerOut" as const;
export type PointerOutEvent = {
  type: typeof PointerOutEventType;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const PointerDownEventType = "PointerDown" as const;
export type PointerDownEvent = {
  type: typeof PointerDownEventType;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const PointerUpEventType = "PointerUp" as const;
export type PointerUpEvent = {
  type: typeof PointerUpEventType;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const PointerMoveEventType = "PointerMove" as const;
export type PointerMoveEvent = {
  type: typeof PointerMoveEventType;
  _pointer: THREE.Vector3;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const DragStartEventType = "DragStart" as const;
export type DragStartEvent = {
  type: typeof DragStartEventType;
  _pointer: THREE.Vector3;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const DraggingEventType = "Dragging" as const;
export type DraggingEvent = {
  type: typeof DraggingEventType;
  _pointer: THREE.Vector3;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const DragEndEventType = "DragEnd" as const;
export type DragEndEvent = {
  type: typeof DragEndEventType;
  _pointer: THREE.Vector3;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const WheelStartEventType = "WheelStart" as const;
export type WheelStartEvent = {
  type: typeof WheelStartEventType;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const WheelingEventType = "Wheeling" as const;
export type WheelingEvent = {
  type: typeof WheelingEventType;
  delta: { x: number; y: number; z: number };
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const WheelEndEventType = "WheelEnd" as const;
export type WheelEndEvent = {
  type: typeof WheelEndEventType;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
};
export const CLICK_ELAPSED_MILLIS = 200;
export const ClickEventType = "Click" as const;
export type ClickEvent = {
  type: typeof ClickEventType;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
  timestamp: 0;
};
export const DBLCLICK_ELAPSED_MILLIS = 350;
export const DoubleClickEventType = "DoubleClick" as const;
export type DoubleClickEvent = {
  type: typeof DoubleClickEventType;
  keys: {
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
  };
  timestamp: 0;
};

const pointMovingOnVerticalPlane = new THREE.Vector3();
const pointMovingOnHorizontalPlane = new THREE.Vector3();
const horizontalPlaneNormalVector = new THREE.Vector3(0, 1, 0);
const horizontalPlane = new THREE.Plane(horizontalPlaneNormalVector, 0);
const verticalPlaneNormalVector = new THREE.Vector3();

export const createHengeCustomEvents = (
  gaiaStore: UseBoundStore<StoreApi<GaiaStoreInterface>>,
  gaiaData: GaiaData,
  hengeData: HengeData,
  hengeObjectGroupRef: React.RefObject<THREE.Group>,
  gaiaMemberRole: GaiaMemberRoleType,
  authTokens: HengeAuthTokens | undefined,
  router: NextRouter,
  gl: THREE.Renderer,
  scene: THREE.Scene,
  camera: Camera,
  pointer: THREE.Vector2,
  raycaster: THREE.Raycaster,
  wheelTimerRef: React.MutableRefObject<NodeJS.Timeout | undefined>,
  setCardVisible: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const onMouseMoveOnBody = (event: MouseEvent) => {
    if (!hengeObjectGroupRef.current) return;

    /**
     * PointerMove
     * @see onPointerMoveOnObject
     * */
    hengeObjectGroupRef.current.dispatchEvent({
      type: PointerMoveEventType,
      keys: {
        ctrlKey: event.ctrlKey,
        altKey: event.altKey,
        shiftKey: event.shiftKey,
      },
    } as THREE.BaseEvent<keyof THREE.Object3DEventMap> & PointerMoveEvent);
  };

  const onMouseUpOnBody = (event: MouseEvent) => {
    if (!hengeObjectGroupRef.current) return;
    if (!hengeObjectGroupRef.current.userData.mousedown) return;
    hengeObjectGroupRef.current.userData.mousedown = false;

    if (hengeData.id === gaiaStore.getState().editingHengeId) {
      if (hengeObjectGroupRef.current.userData.drag) {
        /**
         * DragEnd
         * @see onDragEndOnObject
         * */
        hengeObjectGroupRef.current.dispatchEvent({
          type: DragEndEventType,
          keys: {
            ctrlKey: event.ctrlKey,
            altKey: event.altKey,
            shiftKey: event.shiftKey,
          },
        } as THREE.BaseEvent<keyof THREE.Object3DEventMap> & DragEndEvent);
      }
    }
  };

  const onWheelOnBody = (event: WheelEvent) => {
    if (!hengeObjectGroupRef.current) return;

    if (useGaiaStoreContainer.getState().viewingHengeId) {
      return;
    } else {
      if (
        hengeData.id === gaiaStore.getState().editingHengeId &&
        !hengeObjectGroupRef.current.userData.drag
      ) {
        if (!hengeObjectGroupRef.current.userData.wheel) {
          /**
           * WheelStartEvent
           * @see onWheelStartOnObject
           * */
          hengeObjectGroupRef.current.dispatchEvent({
            type: WheelStartEventType,
            keys: {
              ctrlKey: event.ctrlKey,
              altKey: event.altKey,
              shiftKey: event.shiftKey,
            },
          } as THREE.BaseEvent<keyof THREE.Object3DEventMap> & WheelStartEvent);
        } else {
          /**
           * WheelingEvent
           * @see onWheelingOnObject
           * */
          hengeObjectGroupRef.current.dispatchEvent({
            type: WheelingEventType,
            delta: { x: event.deltaX, y: event.deltaY, z: event.deltaZ },
            keys: {
              ctrlKey: event.ctrlKey,
              altKey: event.altKey,
              shiftKey: event.shiftKey,
            },
          } as THREE.BaseEvent<keyof THREE.Object3DEventMap> & WheelingEvent);
        }
      }
    }
  };

  /** PointerMove */
  const onPointerMoveOnObject = (
    event: THREE.Event<typeof PointerMoveEventType, THREE.Group>,
  ) => {
    const __event = event as THREE.Event<
      typeof PointerMoveEventType,
      THREE.Group
    > &
      PointerMoveEvent;
    const __target = __event.target;

    //

    /* Henge view */
    if (useGaiaStoreContainer.getState().viewingHengeId) {
      return;
    } else {
      /* Gaia view*/
      if (hengeData.id !== gaiaStore.getState().editingHengeId) return;
      if (__target.userData.wheel) return;

      if (__target.userData.mousedown) {
        if (!__target.userData.drag) {
          __target.dispatchEvent({
            type: DragStartEventType,
            keys: __event.keys,
            // keys: {
            //   ctrlKey: __event.ctrlKey,
            //   altKey: __event.altKey,
            //   shiftKey: __event.shiftKey,
            // },
          } as THREE.BaseEvent<keyof THREE.Object3DEventMap> & DragStartEvent);
        } else {
          __target.dispatchEvent({
            type: DraggingEventType,
            keys: __event.keys,
            // keys: {
            //   ctrlKey: __event.ctrlKey,
            //   altKey: __event.altKey,
            //   shiftKey: __event.shiftKey,
            // },
          } as THREE.BaseEvent<keyof THREE.Object3DEventMap> & DraggingEvent);
        }
      }
    }
  };

  /** Wheel */
  const onWheelOnObject = (e: ThreeEvent<WheelEvent>) => {
    const __target = e.eventObject;

    //

    // if (!isOwner && !isGaiaMember) {
    //   return;
    // }

    if (useGaiaStoreContainer.getState().viewingHengeId) {
      return;
    }

    if (__target.userData.drag) return;

    if (hengeData.id === gaiaStore.getState().editingHengeId) {
      if (!__target.userData.wheel) {
        __target.dispatchEvent({
          type: WheelStartEventType,
          // keys: __event.keys,
          keys: {
            ctrlKey: e.ctrlKey,
            altKey: e.altKey,
            shiftKey: e.shiftKey,
          },
        } as THREE.BaseEvent<keyof THREE.Object3DEventMap> & WheelStartEvent);
      } else {
        __target.dispatchEvent({
          type: WheelingEventType,
          // delta: __event.delta,
          delta: { x: e.deltaX, y: e.deltaY, z: e.deltaZ },
          // keys: __event.keys,
          keys: {
            ctrlKey: e.ctrlKey,
            altKey: e.altKey,
            shiftKey: e.shiftKey,
          },
        } as THREE.BaseEvent<keyof THREE.Object3DEventMap> & WheelingEvent);
      }
    }
  };

  /** PointerOver */
  const onPointerOverOnObject = (
    // event: THREE.Event & { type: typeof mouseoverEvent.type } & {
    //   target: THREE.Group;
    // },
    e: ThreeEvent<MouseEvent>,
  ) => {
    const __target = e.eventObject;

    //

    if (!__target.userData.hover) {
      __target.userData.hover = true;
      // setCardVisible(true);

      // if (
      //   !gaiaStore.getState().editingHengeId ||
      //   initialData.id === gaiaStore.getState().editingHengeId
      // ) {
      // }
    }

    if (useGaiaStoreContainer.getState().viewingHengeId) {
      if (hengeData.id === useGaiaStoreContainer.getState().viewingHengeId) {
        // changeCanvasCursorType(gl.domElement, CURSOR_ENUM.pointer);
      }
    } else {
      changeCanvasCursorType(gl.domElement, CURSOR_ENUM.pointer);
    }
  };

  /** PointerOut */
  const onPointerOutOnObject = (e: ThreeEvent<MouseEvent>) => {
    const __target = e.eventObject;

    //

    if (__target.userData.hover) {
      __target.userData.hover = false;
      setCardVisible(false);
      changeCanvasCursorType(gl.domElement, CURSOR_ENUM.default);
    }
  };

  /** PointerDown */
  const onPointerDownOnObject = (e: ThreeEvent<MouseEvent>) => {
    const __target = e.eventObject;

    //

    if (__target.userData.mousedown) return;
    __target.userData.mousedown = true;

    if (hengeData.id !== gaiaStore.getState().editingHengeId) return;

    if (__target.userData.hover) {
      changeCanvasCursorType(gl.domElement, CURSOR_ENUM.grab);
    }
  };

  /** DragStart */
  const onDragStartOnObject = (
    event: THREE.Event<typeof DragStartEventType, THREE.Group>,
  ) => {
    const __event = event as THREE.Event<
      typeof DragStartEventType,
      THREE.Group
    > &
      DragStartEvent;
    // const __target = __event.target;
    const __target = hengeObjectGroupRef.current;
    if (!__target) return;

    //

    // if (!isOwner && !isGaiaMember) {
    //   return;
    // }

    if (useGaiaStoreContainer.getState().viewingHengeId) {
      return;
    }

    __target.userData.drag = true;

    __target.userData.shiftKey = __event.keys.shiftKey;

    raycaster.setFromCamera(pointer, camera);
    const INTERSECTION = raycaster.intersectObject(__target);
    const dragstartPointer = __target.position.clone();
    if (INTERSECTION.length > 0) {
      dragstartPointer.copy(INTERSECTION[0].point);
    }

    __target.userData.dragstartPointer = dragstartPointer.clone();
    __target.userData.dragstartPosition = __target.position.clone();
    __target.userData.dragstartOffset = __target.position
      .clone()
      .sub(dragstartPointer.clone());

    __target.userData.lastPointer = new THREE.Vector3(
      hengeData.position.x,
      hengeData.position.y,
      hengeData.position.z,
    );
    __target.userData.lastPosition = new THREE.Vector3(
      hengeData.position.x,
      hengeData.position.y,
      hengeData.position.z,
    );
    __target.userData.lastRotation = new THREE.Vector3(
      hengeData.rotation.x,
      hengeData.rotation.y,
      hengeData.rotation.z,
    );
    __target.userData.lastSize = new THREE.Vector3(
      hengeData.size.x,
      hengeData.size.y,
      hengeData.size.z,
    );
  };

  /** dragging */
  const onDraggingOnObject = (
    event: THREE.Event<typeof DraggingEventType, THREE.Group>,
  ) => {
    const __event = event as THREE.Event<
      typeof DraggingEventType,
      THREE.Group
    > &
      DraggingEvent;
    const __target = __event.target;

    //

    // if (!isOwner && !isGaiaMember) {
    //   return;
    // }

    if (useGaiaStoreContainer.getState().viewingHengeId) {
      return;
    }

    changeCanvasCursorType(gl.domElement, CURSOR_ENUM.grabbing);

    if (__target.userData.shiftKey !== __event.keys.shiftKey) {
      __target.userData.shiftKey = __event.keys.shiftKey;
      __target.userData.dragstartPosition = __target.position.clone();
    }

    raycaster.setFromCamera(pointer, camera);

    const projectedPointOnHorizontalPlane = __target.userData.dragstartPosition
      .clone()
      .sub(__target.userData.dragstartOffset)
      .projectOnPlane(horizontalPlaneNormalVector);
    const verticalVector = __target.userData.dragstartPosition
      .clone()
      .sub(__target.userData.dragstartOffset)
      .sub(projectedPointOnHorizontalPlane);
    const distanceToHorizontalPlane = verticalVector.dot(
      horizontalPlaneNormalVector,
    );
    horizontalPlane.constant = -1 * distanceToHorizontalPlane;

    /**
     * Move Vertical
     * */
    if (__target.userData.shiftKey) {
      verticalPlaneNormalVector
        .subVectors(camera.position, verticalVector)
        .projectOnPlane(horizontalPlaneNormalVector)
        .normalize();

      const verticalPlane = new THREE.Plane(verticalPlaneNormalVector, 0);
      const projectedPointOnPlane = __target.userData.dragstartPosition
        .clone()
        .sub(__target.userData.dragstartOffset)
        .projectOnPlane(verticalPlaneNormalVector);
      const horizontalVector = __target.userData.dragstartPosition
        .clone()
        .sub(__target.userData.dragstartOffset)
        .sub(projectedPointOnPlane);
      const distanceToVerticalPlane = horizontalVector.dot(
        verticalPlaneNormalVector,
      );
      verticalPlane.constant = -1 * distanceToVerticalPlane;

      raycaster.ray.intersectPlane(verticalPlane, pointMovingOnVerticalPlane);
      pointMovingOnVerticalPlane.add(__target.userData.dragstartOffset);

      const clampedPosition = clampPositionWithinGaia(
        gaiaData,
        pointMovingOnVerticalPlane,
        __target.userData.lastSize,
      );

      __target.userData.lastPosition.y = clampedPosition.y;
      gsapHengePosition(__target, __target.userData.lastPosition);
    } else {
      /**
       * Move Horizontal
       * */
      raycaster.ray.intersectPlane(
        horizontalPlane,
        pointMovingOnHorizontalPlane,
      );
      pointMovingOnHorizontalPlane.add(__target.userData.dragstartOffset);

      const clampedPosition = clampPositionWithinGaia(
        gaiaData,
        pointMovingOnHorizontalPlane,
        __target.userData.lastSize,
      );
      const gridSnappedPosition = snapPositionToGrid(
        gaiaData,
        clampedPosition,
        __target.userData.lastSize,
      );

      if (
        checkHengePositionWithinGaiaGrid(
          gaiaData,
          hengeData.id,
          gridSnappedPosition,
          __target.userData.lastSize,
        )
      ) {
        if (
          __target.userData.lastPosition.x !== gridSnappedPosition.x ||
          __target.userData.lastPosition.z !== gridSnappedPosition.z
        ) {
          const updatedHengeData = {
            ...hengeData,
            position: gridSnappedPosition,
            rotation: __target.userData.lastRotation,
            size: __target.userData.lastSize,
          };

          gaiaStore
            .getState()
            .gaiaStoreActions.updateHengeData(updatedHengeData);
        }

        __target.userData.lastPosition.x = gridSnappedPosition.x;
        __target.userData.lastPosition.z = gridSnappedPosition.z;
      }

      __target.userData.lastPointer = pointMovingOnHorizontalPlane;
      gsapHengePosition(__target, __target.userData.lastPointer);

      // if (
      //   checkHengePositionOverTrashCan(
      //     gaiaStore,
      //     __target.userData.lastPointer,
      //     __target.userData.lastSize,
      //     calcBoundingBox(__target.children[0]),
      //   )
      // ) {
      //   gaiaStore.getState().trashCanObject.dispatchEvent(PointerOverEvent);
      // } else {
      //   gaiaStore.getState().trashCanObject.dispatchEvent(PointerOutEvent);
      // }
    }
  };

  /** dragend */
  const onDragEndOnObject = (
    event: THREE.Event<typeof DragEndEventType, THREE.Group>,
  ) => {
    const __event = event as THREE.Event<typeof DragEndEventType, THREE.Group> &
      DragEndEvent;
    const __target = __event.target;

    //

    // if (!isOwner && !isGaiaMember) {
    //   return;
    // }

    if (useGaiaStoreContainer.getState().viewingHengeId) {
      return;
    }

    makeMaterialOpaque(__target.children[0]);

    if (__target.userData.hover) {
      changeCanvasCursorType(gl.domElement, CURSOR_ENUM.pointer);
    } else {
      changeCanvasCursorType(gl.domElement, CURSOR_ENUM.default);
    }

    __target.userData.drag = false;

    if (!__target.userData.lastPointer) return;

    // if (
    //   checkHengePositionOverTrashCan(
    //     gaiaStore,
    //     __target.userData.lastPointer,
    //     __target.userData.lastSize,
    //     calcBoundingBox(__target.children[0]),
    //   )
    // ) {
    //   gaiaStore
    //     .getState()
    //     .gaiaStoreActions.deleteHengeData(hengeData.id, authTokens)
    //     .then(() => {
    //       window.dataLayer.push(
    //         GA4_CUSTOM_TRIGGER.Fetch.Henge_Delete("Trash Can"),
    //       );
    //
    //       // clearTimeout(__target.userData.dblclickTimer);
    //       gaiaStore.getState().gaiaStoreActions.changeEditingHengeId(null);
    //       changeCanvasCursorType(gl.domElement, CURSOR_ENUM.default);
    //     })
    //     .catch(() => {
    //       useGlobalStore.getState().globalActions.showPopupError();
    //
    //       initHengeUserData(__target, hengeData);
    //       // gsapHengePosition(__target, __hengeData.position);
    //     })
    //     .finally(() => {
    //       console.log("drag end");
    //
    //       gaiaStore.getState().trashCanObject.dispatchEvent(PointerOutEvent);
    //
    //       gsapHengePosition(__target, __target.userData.lastPosition);
    //       gsapHengeRotation(__target, __target.userData.lastRotation);
    //       gsapHengeSize(__target, __target.userData.lastSize);
    //
    //       gaiaStore.getState().gaiaStoreActions.refreshGaiaData(authTokens);
    //       gaiaStore
    //         .getState()
    //         .gaiaStoreActions.refreshHengeDataList(authTokens);
    //
    //       useGlobalStore
    //         .getState()
    //         .globalActions.updateOverlayFeedbackActionCounts("HengeOnGaia", 2);
    //     });
    // } else {
    const updatedHengeData: UpdateHengeRequest = {
      position: __target.userData.lastPosition,
      rotation: __target.userData.lastRotation,
      size: __target.userData.lastSize,
    };

    fetchUpdateHengeAPI(
      { queryParams: { hengeId: hengeData.id }, json: updatedHengeData },
      authTokens,
    )
      .then(() => {
        window.dataLayer.push(
          GA4_CUSTOM_TRIGGER.Fetch.Henge_Update_Transformations(),
        );
      })
      .catch(() => {
        useGlobalStore.getState().globalActions.showPopupError();

        // initHengeUserData(__target, __hengeData);
        // gsapHengePosition(__target, __hengeData.position);
        // gsapHengeRotation(
        //   __target.children
        //     .find((o: THREE.Object3D) => o.name === "hengeCenterObject")
        //     ?.children?.at(0),
        //   __hengeData.rotation,
        // );
        // gsapHengeSize(__target, __hengeData.size);
      })
      .finally(() => {
        gsapHengePosition(__target, __target.userData.lastPosition);
        gsapHengeRotation(__target, __target.userData.lastRotation);
        gsapHengeSize(__target, __target.userData.lastSize);

        useGaiaStoreContainer
          .getState()
          .gaiaStoreContainerActions.refreshGaiaCapsuleDataList(authTokens);
        gaiaStore.getState().gaiaStoreActions.refreshGaiaData(authTokens);
        gaiaStore.getState().gaiaStoreActions.refreshHengeDataList(authTokens);

        useGlobalStore
          .getState()
          .globalActions.updateOverlayFeedbackActionCounts("HengeOnGaia", 2);
      });
    // }
  };

  /** WheelStart */
  const onWheelStartOnObject = (
    event: THREE.Event<typeof WheelStartEventType, THREE.Group>,
  ) => {
    const __event = event as THREE.Event<
      typeof WheelStartEventType,
      THREE.Group
    > &
      WheelStartEvent;
    const __target = __event.target;

    //

    // if (wheelStandby.current) {
    //   return;
    // }
    // if (!isOwner && !isGaiaMember) {
    //   return;
    // }

    if (useGaiaStoreContainer.getState().viewingHengeId) {
      return;
    }

    __target.userData.wheel = true;
    __target.userData.altKey = __event.keys.altKey;
    __target.userData.wheelDelta.set(0, 0, 0);

    __target.userData.lastPointer = new THREE.Vector3(
      hengeData.position.x,
      hengeData.position.y,
      hengeData.position.z,
    );
    __target.userData.lastPosition = new THREE.Vector3(
      hengeData.position.x,
      hengeData.position.y,
      hengeData.position.z,
    );
    __target.userData.lastRotation = new THREE.Vector3(
      hengeData.rotation.x,
      hengeData.rotation.y,
      hengeData.rotation.z,
    );
    __target.userData.lastSize = new THREE.Vector3(
      hengeData.size.x,
      hengeData.size.y,
      hengeData.size.z,
    );
  };

  /** Wheeling */
  const onWheelingOnObject = (
    event: THREE.Event<typeof WheelingEventType, THREE.Group>,
  ) => {
    const __event = event as THREE.Event<
      typeof WheelingEventType,
      THREE.Group
    > &
      WheelingEvent;
    const __target = __event.target;

    //

    // if (!isOwner && !isGaiaMember) {
    //   return;
    // }

    if (useGaiaStoreContainer.getState().viewingHengeId) {
      return;
    }

    clearTimeout(wheelTimerRef.current);

    wheelTimerRef.current = setTimeout(() => {
      __target.dispatchEvent({
        type: WheelEndEventType,
        keys: __event.keys,
      } as THREE.BaseEvent<keyof THREE.Object3DEventMap> & WheelEndEvent);
    }, 500);

    __target.userData.wheelDelta.add(__event.delta);

    /** wheel 방향 전환 시 wheelDelta 초기화 */
    if (
      Math.sign(__target.userData.wheelDelta.y) !== Math.sign(__event.delta.y)
    ) {
      __target.userData.wheelDelta.set(0, 0, 0);
    }

    /** Rotation <-> Size 전환 시 wheelDelta 초기화 */
    if (__target.userData.altKey !== __event.keys.altKey) {
      __target.userData.wheelDelta.set(0, 0, 0);
      __target.userData.altKey = __event.keys.altKey;
    }

    //

    /**
     * Rotate
     */
    if (__target.userData.altKey) {
      const loggedDelta = {
        x: 0,
        y:
          Math.sign(__target.userData.wheelDelta.y) *
          Math.log(Math.max(Math.E, Math.abs(__target.userData.wheelDelta.y))),
        z: 0,
      };

      const updatedRotation = calcRotationByWheelDelta(
        __target.userData.lastRotation,
        loggedDelta,
      );

      const updatedHengeData = {
        ...hengeData,
        position: __target.userData.lastPosition,
        rotation: updatedRotation,
        size: __target.userData.lastSize,
      };

      gaiaStore.getState().gaiaStoreActions.updateHengeData(updatedHengeData);

      __target.userData.lastRotation.y = updatedRotation.y;
      gsapHengeRotation(__target, __target.userData.lastRotation);
    } else {
      /**
       * Size
       */
      const sizeEvenlyWheelDelta = {
        x: __target.userData.wheelDelta.y,
        y: __target.userData.wheelDelta.y,
        z: __target.userData.wheelDelta.y,
      };
      const updatedSize = calcSizeByWheelDelta(
        __target.scale,
        sizeEvenlyWheelDelta,
      );

      const clampedSize = clampSizeWithinGaia(gaiaData, updatedSize);
      const snappedSize = snapSizeToGrid(clampedSize);

      const adjustedPosition = adjustPositionAfterSized(
        gaiaData,
        hengeData.id,
        hengeData.position,
        hengeData.size,
        snappedSize,
      );

      if (
        checkHengePositionWithinGaiaGrid(
          gaiaData,
          hengeData.id,
          adjustedPosition,
          snappedSize,
        )
      ) {
        if (
          __target.userData.lastPosition.x !== adjustedPosition.x ||
          __target.userData.lastPosition.z !== adjustedPosition.z ||
          __target.userData.lastSize.x !== snappedSize.x ||
          __target.userData.lastSize.y !== snappedSize.y ||
          __target.userData.lastSize.z !== snappedSize.z
        ) {
          const updatedHengeData = {
            ...hengeData,
            position: adjustedPosition,
            rotation: __target.userData.lastRotation,
            size: snappedSize,
          };

          gaiaStore
            .getState()
            .gaiaStoreActions.updateHengeData(updatedHengeData);
        }

        __target.userData.lastPosition = adjustedPosition;
        __target.userData.lastSize = snappedSize;

        gsapHengePosition(__target, adjustedPosition);
        gsapHengeSize(__target, clampedSize);
      }
    }
  };

  /** WheelEnd */
  const onWheelEndOnObject = (
    event: THREE.Event<typeof WheelEndEventType, THREE.Group>,
  ) => {
    const __event = event as THREE.Event<
      typeof WheelEndEventType,
      THREE.Group
    > &
      WheelEndEvent;
    const __target = __event.target;

    //

    // if (!isOwner && !isGaiaMember) {
    //   return;
    // }

    if (useGaiaStoreContainer.getState().viewingHengeId) {
      return;
    }

    __target.userData.wheel = false;
    __target.userData.wheelDelta = new THREE.Vector3();

    const updatedHengeData: UpdateHengeRequest = {
      position: __target.userData.lastPosition,
      rotation: __target.userData.lastRotation,
      size: __target.userData.lastSize,
    };

    // wheelStandby.current = true;
    fetchUpdateHengeAPI(
      { queryParams: { hengeId: hengeData.id }, json: updatedHengeData },
      authTokens,
    )
      .then(() => {
        window.dataLayer.push(
          GA4_CUSTOM_TRIGGER.Fetch.Henge_Update_Transformations(),
        );
      })
      .catch(() => {
        useGlobalStore.getState().globalActions.showPopupError();

        // initHengeUserData(__target, __hengeData);
        // gsapHengePosition(__target, __hengeData.position);
        // gsapHengeRotation(
        //   __target.children
        //     .find((o: THREE.Object3D) => o.name === "hengeCenterObject")
        //     ?.children?.at(0),
        //   __hengeData.rotation,
        // );
        // gsapHengeSize(__target, __hengeData.size);
      })
      .finally(() => {
        gsapHengePosition(__target, __target.userData.lastPosition);
        gsapHengeRotation(__target, __target.userData.lastRotation);
        gsapHengeSize(__target, __target.userData.lastSize);

        useGaiaStoreContainer
          .getState()
          .gaiaStoreContainerActions.refreshGaiaCapsuleDataList(authTokens);
        gaiaStore.getState().gaiaStoreActions.refreshGaiaData(authTokens);
        gaiaStore.getState().gaiaStoreActions.refreshHengeDataList(authTokens);

        // wheelStandby.current = false;

        useGlobalStore
          .getState()
          .globalActions.updateOverlayFeedbackActionCounts("HengeOnGaia", 2);
      });
  };

  /** click */
  const onClickOnObject = () => {
    // const __target = e.eventObject;

    //

    // if (!__target.userData.hover) return;

    const __viewingHengeId = useGaiaStoreContainer.getState().viewingHengeId;
    if (__viewingHengeId) return;

    const __uploadingHengeIndices =
      gaiaStore.getState().gaiaObjectUploadingHengeIndices;
    if (__uploadingHengeIndices) {
      gaiaStore.setState({ gaiaObjectUploadingHengeIndices: null });
      return;
    }

    const __editingHengeId = gaiaStore.getState().editingHengeId;

    if (!__editingHengeId) {
      // clearTimeout(__target.userData.dblclickTimer);
      // __target.userData.dblclickTimer = setTimeout(() => {
      //   window.dataLayer.push(GA4_CUSTOM_TRIGGER.Click.Henge_Object());
      //   gaiaStore.getState().gaiaStoreActions.changeEditingHengeId(hengeData.id);
      // }, DBLCLICK_ELAPSED_MILLIS);

      useGlobalStore
        .getState()
        .globalActions.updateOverlayFeedbackActionCounts("HengeOnGaia", 5);

      router
        .push(
          `/${gaiaData.owner.metadata.username}/gaia/${gaiaData.uid}?henge=${hengeData.uid}`,
        )
        .then(() => {
          window.dataLayer.push(GA4_CUSTOM_TRIGGER.DoubleClick.Henge_Object());
        });
    }
  };

  /** dblclick */
  const onDoubleClickOnObject = () => {
    // const __target = e.eventObject;
    //
    // if (!__target.userData.hover) return;
    // if (gaiaStore.getState().editingHengeId) return;
    // clearTimeout(__target.userData.dblclickTimer);
    // gaiaStore.getState().gaiaStoreActions.changeEditingHengeId(null);
    //
    // useGlobalStore
    //   .getState()
    //   .globalActions.updateOverlayFeedbackActionCounts("HengeOnGaia", 5);
    //
    // router
    //   .push(
    //     `/${gaiaData.owner.metadata.username}/gaia/${gaiaData.uid}?henge=${hengeData.uid}`,
    //   )
    //   .then(() => {
    //     window.dataLayer.push(GA4_CUSTOM_TRIGGER.DoubleClick.Henge_Object());
    //   });
  };

  return {
    document: {
      handler: {},
      body: {
        handler: {
          onMouseMoveOnBody,
          onWheelOnBody,
          onMouseUpOnBody,
        },
      },
    },
    hengeObjectGroup: {
      handler: {
        onPointerMoveOnObject,
        onWheelOnObject,
        onPointerOverOnObject,
        onPointerOutOnObject,
        onPointerDownOnObject,
        onDragStartOnObject,
        onDraggingOnObject,
        onDragEndOnObject,
        onWheelStartOnObject,
        onWheelingOnObject,
        onWheelEndOnObject,
        onClickOnObject,
        onDoubleClickOnObject,
      },
      hengeObjectAutoScaleRoot: {
        handler: {},
        HengeObjectPrimitive: {
          handler: {},
        },
      },
    },
  };
};
