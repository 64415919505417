import HENGE from "@henge-inc/global-types";

export const GAIA_MEMBER_ROLE = {
  GUEST: "GUEST",
  MEMBER: "MEMBER",
  OWNER: "OWNER",
} as const;
export type GaiaMemberRoleType = HENGE.ValuesTypeFromObject<
  typeof GAIA_MEMBER_ROLE
>;

export const ROSETTA_CODE = {
  DOCUMENT: "DOCUMENT",
  CAMERA: "CAMERA",
  LIGHT: "LIGHT",
  IDEATION: "IDEATION",
  MATERIAL: "MATERIAL",
} as const;
export type RosettaCodeType = HENGE.ValuesTypeFromObject<typeof ROSETTA_CODE>;

export const ROSETTA_RELEASE_STATUS = {
  INTERNAL_REVIEW: "INTERNAL_REVIEW",
  COMING_SOON: "COMING_SOON",
  RELEASED: "RELEASED",
  NOT_IN_SUPPORT: "NOT_IN_SUPPORT",
} as const;
export type RosettaReleaseStatusType = HENGE.ValuesTypeFromObject<typeof ROSETTA_RELEASE_STATUS>;