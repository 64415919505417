import { NextApiResponse } from "next";
import { ERROR_ENUM, ERROR_INFO } from "@/types/error-types";
import { ErrorWithGaiaImage } from "@/components/error/ErrorWithGaiaImage";

const Error = ({
  statusCode,
}: {
  statusCode?: NextApiResponse["statusCode"];
}) => {
  return (
    <ErrorWithGaiaImage
      errorType={
        Object.values(ERROR_INFO).find(
          (errorInfo) => errorInfo.code === statusCode,
        )?.name ?? ERROR_ENUM.UNKNOWN
      }
    />
  );
};

Error.getInitialProps = async ({
  res,
  err,
}: {
  res: NextApiResponse;
  err: NextApiResponse;
}) => {
  const statusCode = res
    ? res.statusCode
    : err
      ? err.statusCode
      : ERROR_INFO.UNKNOWN.code;

  return { statusCode };
};

export default Error;
