import { HengeAuthTokens } from "@/classes/auth/Auth";
import { GA4_CUSTOM_TRIGGER } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import {
  CreateGaiaRequest,
  DropGaiaMemberRequest,
  InviteGaiaMemberRequest,
  UpdateGaiaMemberRoleRequest,
  UpdateGaiaRequest,
  UpdateGaiaThumbnailRequest,
} from "@/types/api/gaia/request/gaia-request";
import {
  GaiaCapsuleResponse,
  GaiaResponse,
} from "@/types/api/gaia/response/gaia-response";
import { CreateHengePinRequest } from "@/types/api/henge-pin/request/henge-pin-request";
import { HengePinResponse } from "@/types/api/henge-pin/response/henge-pin-response";
import {
  CreateHengePinnedRosettaCellRequest,
  UpdateHengePinnedRosettaCellLocationRequest,
} from "@/types/api/henge-pinned-rosetta-cell/request/henge-pinned-rosetta-cell-request";
import {
  CreateHengeRequest,
  UpdateHengeRequest,
  UpdateHengeShareScopeRequest,
} from "@/types/api/henge/request/henge-request";
import {
  HengeListResponse,
  HengeResponse,
  LimitMaxGaiaFileSizeResponse,
  LimitMaxHengeFileSizeResponse,
} from "@/types/api/henge/response/henge-response";
import {
  CreateMemberDetailRequest,
  PasswordChangeRequest,
} from "@/types/api/member/request/member-request";
import {
  MemberResponse,
  SearchMemberResponse,
} from "@/types/api/member/response/member-response";
import { GenerateChannelTalkHashResponse } from "@/types/api/plugins/channelTalk/response/channel-talk-response";
import { DataResponse, HResponse } from "@/types/api/response";
import {
  CreateRosettaCellRequest,
  UpdateRosettaCellDataRequest,
  UpdateRosettaCellIdxRequest,
  UpdateRosettaCellLocationRequest,
  UpdateRosettaCellMapRequest,
  UploadRosettaCellImageByUrlRequest,
} from "@/types/api/rosetta-cell/request/rosetta-cell-request";
import {
  CreateRosettaCellResponse,
  RosettaCellResponse,
  UploadFileResponse,
} from "@/types/api/rosetta-cell/response/rosetta-cell-response";
import { UpdateRosettaDataRequest } from "@/types/api/rosetta/request/document-rosetta-request";
import { DocumentRosettaResponse } from "@/types/api/rosetta/response/document-rosetta-response";
import { RosettaRegisteredListResponse } from "@/types/api/rosetta/response/tuning-shop-response";
import {
  FetchWithHengeAuthCallback,
  FetchWithNoAuthCallback,
} from "@/utils/api/fetch-types";
import fetchWithHengeAuth from "@/utils/auth/fetchWithHengeAuth";
import { MaterialLibraryData } from "@/types/data-types";
import fetchWithNoAuth from "@/utils/auth/fetchWithNoAuth";

//////////////////
//    member    //
//////////////////

export const fetchGetMemberAPI: FetchWithHengeAuthCallback<MemberResponse> = (
  args,
  authTokens,
) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/member",
      ...args,
    },
    authTokens,
    true,
  );
};

export const fetchGenerateChannelTalkHashAPI: FetchWithHengeAuthCallback<
  GenerateChannelTalkHashResponse,
  { username: string }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/channel-talk",
      ...args,
    },
    authTokens,
  );
};

export const fetchSearchMemberAPI: FetchWithHengeAuthCallback<
  SearchMemberResponse,
  { q: string }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/member/search",
      ...args,
    },
    authTokens,
  );
};

/////////////////////////
//    member-detail    //
/////////////////////////

export const fetchCreateMemberDetailAPI: FetchWithHengeAuthCallback<
  HResponse,
  { json: CreateMemberDetailRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/member/detail",
      ...args,
    },
    authTokens,
  );
};

/////////////////////////
//    member-manage    //
/////////////////////////

export const fetchPasswordChangeAPI: FetchWithHengeAuthCallback<
  HResponse,
  { json: PasswordChangeRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/member/manage/password/change",
      ...args,
    },
    authTokens,
  );
};

////////////////
//    gaia    //
////////////////

export const fetchMemberHomeGaiaAPI: FetchWithHengeAuthCallback<
  GaiaResponse,
  { queryParams: { username: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/gaia/home",
      ...args,
    },
    authTokens,
  );
};

export const fetchCheckGaiaShareScopeAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { uid: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/gaia/scope",
      ...args,
    },
    authTokens,
  );
};

export const fetchGetGaiaCapsuleListAPI: FetchWithHengeAuthCallback<
  GaiaCapsuleResponse,
  { queryParams: { username: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/gaia/capsule",
      ...args,
    },
    authTokens,
  );
};

export const fetchGetGaiaAPI: FetchWithHengeAuthCallback<
  GaiaResponse,
  { queryParams: { gaiaId: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/gaia",
      ...args,
    },
    authTokens,
  );
};

export const fetchCreateGaiaAPI: FetchWithHengeAuthCallback<
  GaiaResponse,
  { json: CreateGaiaRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .POST<GaiaResponse, { json: CreateGaiaRequest }>(
      {
        path: "/api/v1/gaia",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      try {
        window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Gaia_Create());
      } catch (e) {
        void e;
      }

      return res;
    });
};

export const fetchUpdateGaiaAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { gaiaId: number };
    json: UpdateGaiaRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/gaia",
      ...args,
    },
    authTokens,
  );
};

export const fetchUpdateGaiaThumbnailAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { gaiaId: number };
    json: UpdateGaiaThumbnailRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/gaia/thumbnail",
      ...args,
    },
    authTokens,
  );
};

export const fetchDeleteGaiaAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { gaiaId: number };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .DELETE<
      HResponse,
      {
        queryParams: { gaiaId: number };
      }
    >(
      {
        path: "/api/v1/gaia",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      try {
        window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Gaia_Delete());
      } catch (e) {
        void e;
      }

      return res;
    });
};

///////////////////////
//    gaia-member    //
///////////////////////

export const fetchInviteGaiaMemberAPI: FetchWithHengeAuthCallback<
  HResponse,
  { json: InviteGaiaMemberRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/gaia-member/invite",
      ...args,
    },
    authTokens,
  );
};

export const fetchUpdateGaiaMemberRoleAPI: FetchWithHengeAuthCallback<
  HResponse,
  { json: UpdateGaiaMemberRoleRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/gaia-member/update",
      ...args,
    },
    authTokens,
  );
};

export const fetchDropGaiaMemberAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    json: DropGaiaMemberRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/gaia-member/drop",
      ...args,
    },
    authTokens,
  );
};

export const fetchSearchMemberToInviteAPI: FetchWithHengeAuthCallback<
  SearchMemberResponse,
  {
    queryParams: {
      gaiaId: number;
      q: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/gaia-member/search",
      ...args,
    },
    authTokens,
  );
};

/////////////////
//    henge    //
/////////////////

export const fetchGetHengeListAPI: FetchWithHengeAuthCallback<
  HengeListResponse,
  {
    queryParams: {
      gaiaId: number;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/henge/list",
      ...args,
    },
    authTokens,
  );
};

/**
 * @return Promise<HengeResponse | LimitMaxGaiaFileSizeResponse | LimitMaxHengeFileSizeResponse>
 */
export function fetchCreateHengeAPI(
  createHengeRequest: CreateHengeRequest,
  files: File[],
  authTokens: HengeAuthTokens | undefined,
): Promise<
  HengeResponse | LimitMaxGaiaFileSizeResponse | LimitMaxHengeFileSizeResponse
> {
  const hengeDataInputElement = document.createElement("input");
  hengeDataInputElement.type = "text";
  hengeDataInputElement.name = "createHengeRequest";
  hengeDataInputElement.value = JSON.stringify(createHengeRequest);

  const dataTransfer = new DataTransfer();
  files.forEach((file) => {
    dataTransfer.items.add(file);
  });

  const fileInputElement = document.createElement("input");
  fileInputElement.type = "file";
  fileInputElement.name = "files";
  fileInputElement.files = dataTransfer.files;

  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";
  form.append(hengeDataInputElement);
  form.append(fileInputElement);

  const formData = new FormData(form);

  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/henge",
      ...{
        init: {
          body: formData,
        },
      },
    },
    authTokens,
  );
}

export const fetchUpdateHengeAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: {
      hengeId: number;
    };
    json: UpdateHengeRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/henge",
      ...args,
    },
    authTokens,
  );
};

// export const fetchUpdateHengeThumbnailAPI: FetchWithHengeAuthCallback<
//   HResponse,
//   {
//     queryParams: {
//       hengeId: number;
//     };
//     json: UpdateHengeThumbnailRequest;
//   }
// > = (args, authTokens) => {
//   return fetchWithHengeAuth.PUT(
//     {
//       path: "/api/v1/henge/thumbnail",
//       ...args,
//     },
//     authTokens,
//   );
// };

export function fetchUpdateHengeOGImageAPI(
  hengeId: number,
  image: File,
  authTokens: HengeAuthTokens | undefined,
): Promise<HengeResponse> {
  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(image);

  const fileInputElement = document.createElement("input");
  fileInputElement.type = "file";
  fileInputElement.name = "image";
  fileInputElement.files = dataTransfer.files;

  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";
  form.append(fileInputElement);

  const formData = new FormData(form);

  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/henge/og-image",
      ...{
        queryParams: {
          hengeId,
        },
        init: {
          body: formData,
        },
      },
    },
    authTokens,
  );
}

export const fetchUpdateHengeShareScopeAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: {
      hengeId: number;
    };
    json: UpdateHengeShareScopeRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/henge/share/scope",
      ...args,
    },
    authTokens,
  );
};

export const fetchDeleteHengeAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: {
      hengeId: number;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/henge",
      ...args,
    },
    authTokens,
  );
};

/////////////////
//    Asset    //
/////////////////

export const fetchGetAssetSignedUrl: FetchWithHengeAuthCallback<
  DataResponse<string>,
  {
    queryParams: {
      url: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/asset/signed-url",
      ...args,
    },
    authTokens,
    true,
  );
};

export const fetchGenerateAutoRig: FetchWithHengeAuthCallback<
  DataResponse<string>,
  {
    queryParams: {
      assetId: number;
      height: number;
      x: number;
      y: number;
      z: number;
      markers: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/asset/auto-rig",
      ...args,
    },
    authTokens,
  );
};

///////////////////////
//    henge-layer    //
///////////////////////

/////////////////////
//    henge-pin    //
/////////////////////

export const fetchCreateHengePinAPI: FetchWithHengeAuthCallback<
  HengePinResponse,
  {
    json: CreateHengePinRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/henge-pin",
      ...args,
    },
    authTokens,
    true,
  );
};

/////////////////////////////////////
//    henge-pinned-rosetta-cell    //
/////////////////////////////////////
export const fetchCreateHengePinnedRosettaCellAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: {
      hengePinId: number;
      rosettaCellId: number;
    };
    json: CreateHengePinnedRosettaCellRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/henge-pin/rosetta-cell",
      ...args,
    },
    authTokens,
  );
};

export const fetchUpdateHengePinnedRosettaCellLocationAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { hengePinId: number; rosettaCellId: number };
    json: UpdateHengePinnedRosettaCellLocationRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/henge-pin/rosetta-cell",
      ...args,
    },
    authTokens,
  );
};

export const fetchDeleteHengePinnedRosettaCellAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { hengePinId: number; rosettaCellId: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/henge-pin/rosetta-cell",
      ...args,
    },
    authTokens,
  );
};

///////////////////
//    rosetta    //
///////////////////

export const fetchGetRosettaAPI: FetchWithHengeAuthCallback<
  DocumentRosettaResponse,
  {
    queryParams: {
      rosettaId: number;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/henge-rosetta",
      ...args,
    },
    authTokens,
  );
};

export const fetchAddRosettaAPI: FetchWithHengeAuthCallback<
  DocumentRosettaResponse,
  {
    json: {
      hengeId: number;
      codeName: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      DocumentRosettaResponse,
      {
        json: {
          hengeId: number;
          codeName: string;
        };
      }
    >(
      {
        path: "/api/v1/henge-rosetta/add",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Tuning_Shop_Add_Rosetta());

      return res;
    });
};

export const fetchRemoveRosettaAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    json: {
      hengeId: number;
      codeName: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      {
        json: {
          hengeId: number;
          codeName: string;
        };
      }
    >(
      {
        path: "/api/v1/henge-rosetta/remove",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(
        GA4_CUSTOM_TRIGGER.Fetch.Tuning_Shop_Remove_Rosetta(),
      );

      return res;
    });
};

export const fetchUpdateRosettaDataAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { rosettaId: number }; json: UpdateRosettaDataRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      { queryParams: { rosettaId: number }; json: UpdateRosettaDataRequest }
    >(
      {
        path: "/api/v1/henge-rosetta",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

export const fetchRosettaMaterialLibraryAPI: FetchWithNoAuthCallback<
  MaterialLibraryData[]
> = (args) => {
  return fetchWithNoAuth
    .GET<MaterialLibraryData[], Record<string, never>>({
      path: "/api/v1/henge-rosetta/material-library",
      ...args,
    })
    .then((res) => {
      return res;
    });
};

////////////////////////
//    rosetta-cell    //
////////////////////////

export const fetchGetRosettaCellAPI: FetchWithHengeAuthCallback<
  RosettaCellResponse,
  {
    queryParams: {
      id: number[] | number;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/rosetta-cell",
      ...args,
    },
    authTokens,
  );
};

export function fetchUploadRosettaCellFileAPI(
  queryParams: { rosettaCellId: number },
  file: File,
  authTokens: HengeAuthTokens | undefined,
): Promise<UploadFileResponse> {
  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(file);

  const fileInputElement = document.createElement("input");
  fileInputElement.type = "file";
  fileInputElement.name = "file";
  fileInputElement.files = dataTransfer.files;

  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";
  form.append(fileInputElement);

  const formData = new FormData(form);

  return fetchWithHengeAuth
    .POST<
      UploadFileResponse,
      { queryParams: { rosettaCellId: number }; init: { body: FormData } }
    >(
      {
        path: "/api/v1/rosetta-cell/file",
        ...{ queryParams, init: { body: formData } },
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
}

export const fetchUploadRosettaCellImageByUrlAPI: FetchWithHengeAuthCallback<
  UploadFileResponse,
  {
    queryParams: { rosettaCellId: number };
    json: UploadRosettaCellImageByUrlRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST<
    UploadFileResponse,
    {
      queryParams: { rosettaCellId: number };
      json: UploadRosettaCellImageByUrlRequest;
    }
  >({ path: "/api/v1/rosetta-cell/image-url", ...args }, authTokens);
};

export const fetchCreateRosettaCellAPI: FetchWithHengeAuthCallback<
  CreateRosettaCellResponse,
  { json: CreateRosettaCellRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .POST<CreateRosettaCellResponse, { json: CreateRosettaCellRequest }>(
      {
        path: "/api/v1/rosetta-cell",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

export const fetchUpdateRosettaCellDataAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { rosettaCellId: number };
    json: UpdateRosettaCellDataRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      {
        queryParams: { rosettaCellId: number };
        json: UpdateRosettaCellDataRequest;
      }
    >(
      {
        path: "/api/v1/rosetta-cell",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

/**
 * @deprecated
 * */
export const fetchUpdateRosettaCellIdxAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { rosettaCellId: number }; json: UpdateRosettaCellIdxRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      {
        queryParams: { rosettaCellId: number };
        json: UpdateRosettaCellIdxRequest;
      }
    >(
      {
        path: "/api/v1/rosetta-cell/idx",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

export const fetchUpdateRosettaCellLocationAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { rosettaCellId: number };
    json: UpdateRosettaCellLocationRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      {
        queryParams: { rosettaCellId: number };
        json: UpdateRosettaCellLocationRequest;
      }
    >(
      {
        path: "/api/v1/rosetta-cell/location",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

export const fetchUpdateRosettaCellMapAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { rosettaId: number };
    json: UpdateRosettaCellMapRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      {
        queryParams: { rosettaId: number };
        json: UpdateRosettaCellMapRequest;
      }
    >(
      {
        path: "/api/v1/rosetta-cell/map",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

export const fetchDeleteRosettaCellAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { rosettaCellId: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .DELETE<HResponse, { queryParams: { rosettaCellId: number } }>(
      {
        path: "/api/v1/rosetta-cell",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

///////////////////////
//    tuning-shop    //
///////////////////////

export const fetchGetTuningShopRosettaAPI: FetchWithHengeAuthCallback<
  RosettaRegisteredListResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/tuning-shop",
      ...args,
    },
    authTokens,
  );
};

export const fetchGetTuningShopReleasedRosettaAPI: FetchWithHengeAuthCallback<
  RosettaRegisteredListResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/tuning-shop/released",
      ...args,
    },
    authTokens,
  );
};

////////////////////
//    feedback    //
////////////////////

export const fetchSubmitOverlayFeedbackAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    json: {
      feedback: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/feedback/overlay",
      ...args,
    },
    authTokens,
  );
};
