import ReactDOM from "react-dom";
import { useEffect, useState } from "react";

export interface PortalProps {
  children?: React.ReactNode;
  root?: HTMLElement | null;
}

export const Portal = ({ children, root }: PortalProps) => {
  const [isCSR, setCSR] = useState<boolean>(false);

  useEffect(() => {
    setCSR(true);
  }, []);

  if (!isCSR) {
    return null;
  } else if (root) {
    return ReactDOM.createPortal(children, root);
  } else if (typeof document === "object") {
    return ReactDOM.createPortal(children, document.body);
  } else {
    return null;
  }
};
