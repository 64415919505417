import HENGE from "@henge-inc/global-types";
import * as THREE from "three";
import { CursorProps } from "../../components/cursor/CursorElement";
import { create, StoreApi, UseBoundStore } from "zustand";
import { HngVector2 } from "@/utils/threeUtil";
import useGaiaStoreContainer from "@/engine/viewer/core/useGaiaStoreContainer";

export interface CursorStoreInterface {
  cursorProps: CursorProps;
  mouse: MouseEvent;
  prefix: HENGE.CursorPrefix | null;
  prefixedNameX: `${HENGE.CursorPrefix}X` | "x";
  prefixedNameY: `${HENGE.CursorPrefix}Y` | "y";
  prefixedMouseXY: [number, number];
  canvasMouse: HngVector2;
  canvasPointer: THREE.Vector2;
  init: (cursorProps: CursorProps, prefix?: HENGE.CursorPrefix) => void;
  reset: () => void;
  eventListeners: {
    [K in keyof Partial<WindowEventMap>]: ((
      this: Window,
      ev: WindowEventMap[K],
    ) => void)[];
  };
  cursorStoreActions: {
    changeAbbrName: (abbrName: string) => void;
    changeColour: (colour: HENGE.PersonalColourType) => void;
    updateMouse: (mouse: MouseEvent) => void;
    updateCanvasPointer: (canvasPointer: HngVector2) => void;
    addEventListener: <K extends keyof WindowEventMap>(
      type: K,
      listener: (this: Window, ev: WindowEventMap[K]) => void,
      options?: boolean | AddEventListenerOptions,
    ) => void;
    removeEventListener: <K extends keyof WindowEventMap>(
      type: K,
      listener: (this: Window, ev: WindowEventMap[K]) => void,
      options?: boolean | EventListenerOptions,
    ) => void;
  };
}

export function createCursorStore() {
  return create<CursorStoreInterface>((set, get) => ({
    cursorProps: null as unknown as CursorProps,
    mouse: null as unknown as MouseEvent,
    prefix: "client",
    prefixedNameX: "clientX",
    prefixedNameY: "clientY",
    prefixedMouseXY: [-10000, -10000],
    canvasMouse: { x: -10000, y: -10000 },
    canvasPointer: new THREE.Vector2(-10000, -10000),
    eventListeners: {},
    init: (cursorProps, prefix = "client") => {
      if (!get().cursorProps) {
        const prefixedNameX: `${HENGE.CursorPrefix}X` | "x" = get().prefix
          ? `${get().prefix as HENGE.CursorPrefix}X`
          : "x";
        const prefixedNameY: `${HENGE.CursorPrefix}Y` | "y" = get().prefix
          ? `${get().prefix as HENGE.CursorPrefix}Y`
          : "y";

        set(() => ({
          cursorProps: {
            abbrName: cursorProps.abbrName,
            personalColour: cursorProps.personalColour,
          },
          prefix,
          prefixedNameX,
          prefixedNameY,
        }));
      }
    },
    reset: <K extends keyof WindowEventMap>() => {
      Object.entries(get().eventListeners).forEach((eventListener) => {
        const eventType = eventListener[0] as K;
        const listeners = eventListener[1] as ((
          this: Window,
          ev: WindowEventMap[K],
        ) => void)[];

        listeners.forEach((listener) => {
          get().cursorStoreActions.removeEventListener(eventType, listener);
        });
      });

      set(() => ({
        cursorProps: null as unknown as CursorProps,
      }));
    },
    cursorStoreActions: {
      changeAbbrName: (abbrName) =>
        set((state) => ({
          cursorProps: {
            ...state.cursorProps,
            abbrName: abbrName,
          },
        })),
      changeColour: (colour) =>
        set((state) => ({
          cursorProps: {
            ...state.cursorProps,
            personalColour: colour,
          },
        })),
      updateMouse: (mouse) => {
        const x = mouse[get().prefixedNameX];
        const y = mouse[get().prefixedNameY];

        const canvasSize = useGaiaStoreContainer
          .getState()
          .currentGaiaStore?.getState()
          .gaiaLinkedViewerStore?.getState()?.size;

        if (canvasSize) {
          const canvasX = Math.min(
            canvasSize.left + canvasSize.width,
            Math.max(canvasSize.left, x),
          );
          const canvasY = Math.min(
            canvasSize.top + canvasSize.height,
            Math.max(canvasSize.top, y),
          );
          const canvasCoordinateX = (canvasX / canvasSize.width) * 2 - 1;
          const canvasCoordinateY = (canvasY / canvasSize.height) * -2 + 1;

          get().canvasPointer.set(canvasCoordinateX, canvasCoordinateY);
        }

        set(() => ({
          mouse: mouse,
          prefixedMouseXY: [x, y],
        }));
      },
      updateCanvasPointer: (canvasPointer) => {
        const canvasSize = useGaiaStoreContainer
          .getState()
          .currentGaiaStore?.getState()
          .gaiaLinkedViewerStore?.getState()?.size;

        const mouse = {
          x: canvasSize.left + ((canvasPointer.x + 1) / 2) * canvasSize.width,
          y: canvasSize.top + ((canvasPointer.y - 1) / -2) * canvasSize.height,
        };

        get().canvasPointer.set(canvasPointer.x, canvasPointer.y);

        set(() => ({
          prefixedMouseXY: [mouse.x, mouse.y],
        }));
      },
      addEventListener: <K extends keyof WindowEventMap>(
        type: K,
        listener: (this: Window, ev: WindowEventMap[K]) => void,
        options?: boolean | AddEventListenerOptions,
      ) => {
        window.addEventListener(type, listener, options);
      },
      removeEventListener: <K extends keyof WindowEventMap>(
        type: K,
        listener: (this: Window, ev: WindowEventMap[K]) => void,
        options?: boolean | AddEventListenerOptions,
      ) => {
        window.addEventListener(type, listener, options);
      },
    },
  }));
}

export function createMyCursorStore() {
  const cursorStore = createCursorStore();

  const mousemoveHandler = (evt: MouseEvent) => {
    cursorStore.getState().cursorStoreActions.updateMouse(evt);
  };

  cursorStore
    .getState()
    .cursorStoreActions.addEventListener("mousemove", mousemoveHandler);
  cursorStore
    .getState()
    .cursorStoreActions.addEventListener("dragover", mousemoveHandler);

  return cursorStore;
}

export const useCursorProps = (
  cursorStore: UseBoundStore<StoreApi<CursorStoreInterface>>,
) => cursorStore((state) => state.cursorProps);
export const useInitCursorStore = (
  cursorStore: UseBoundStore<StoreApi<CursorStoreInterface>>,
) => cursorStore((state) => state.init);
export const useResetCursorStore = (
  cursorStore: UseBoundStore<StoreApi<CursorStoreInterface>>,
) => cursorStore((state) => state.reset);
export const usePrefixedMouseXY = (
  cursorStore: UseBoundStore<StoreApi<CursorStoreInterface>>,
) => cursorStore((state) => state.prefixedMouseXY);
export const useMouse = (
  cursorStore: UseBoundStore<StoreApi<CursorStoreInterface>>,
) => cursorStore((state) => state.mouse);
export const useCanvasPointer = (
  cursorStore: UseBoundStore<StoreApi<CursorStoreInterface>>,
) => cursorStore((state) => state.canvasPointer);
export const useCursorStoreActions = (
  cursorStore: UseBoundStore<StoreApi<CursorStoreInterface>>,
) => cursorStore((state) => state.cursorStoreActions);
