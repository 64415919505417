import { GridGuidance } from "@/components/grid/GridGuidance";
import GridItem from "@/components/grid/GridItem";
import { ERROR_INFO, ErrorEnumType } from "@/types/error-types";
import { css } from "@emotion/react";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";
import Image from "next/image";
import { useRouter } from "next/router";

interface ErrorBackToHomeProps {
  errorType: ErrorEnumType;
}

export const ErrorWithGaiaImage = ({ errorType }: ErrorBackToHomeProps) => {
  const gridRuleset: HENGE.GridRuleType[] = [
    {
      minWidth: 0,
      maxWidth: 600,
      gridColumn: "1 / -1",
      gridRow: "2 / -4",
    },
    {
      minWidth: 600,
      maxWidth: 1024,
      gridColumn: "2 / -2",
      gridRow: "2 / -4",
    },
    {
      minWidth: 1024,
      maxWidth: 1440,
      gridColumn: "2 / -2",
      gridRow: "2 / -4",
    },
    {
      minWidth: 1440,
      maxWidth: Number.MAX_SAFE_INTEGER,
      gridColumn: "2 / -2",
      gridRow: "2 / -4",
    },
  ];

  //================================================================

  const tick = Math.floor(3 * Math.random());

  const router = useRouter();

  return (
    <GridGuidance>
      <GridItem gridRuleset={gridRuleset}>
        <div
          css={css({
            userSelect: "none",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            width: "100%",
            height: "100%",
          })}
        >
          <div
            css={css({
              position: "relative",
              width: "100%",
              height: "auto",
              aspectRatio: "360 / 197",
            })}
          >
            <Image
              src={`/assets/images/error/${errorType}.webp`}
              fill
              alt={"error"}
              style={{
                objectFit: "contain",
              }}
              draggable={false}
            />
          </div>
          {/*<HUI.Emoji*/}
          {/*  symbol={ERROR_INFO[errorType].emoji}*/}
          {/*  size={HENGE.DETAILED_SIZE.x2Large}*/}
          {/*/>*/}
          <HUI.Text
            kind={HENGE.TEXT_KIND.heading2}
            align={HENGE.TEXT_ALIGN.center}
          >
            {ERROR_INFO[errorType].title[tick] ||
              ERROR_INFO[errorType].title[0]}
          </HUI.Text>
          {/*<HUI.Text*/}
          {/*  kind={HENGE.TEXT_KIND.body2}*/}
          {/*  align={HENGE.TEXT_ALIGN.center}*/}
          {/*>*/}
          {/*  {ERROR_INFO[errorType].description}*/}
          {/*</HUI.Text>*/}
          <HUI.Button
            kind={HENGE.BUTTON_KIND.filled}
            size={HENGE.DETAILED_SIZE.small}
            colour={HENGE.THEME_COLOUR.mono}
            onClick={() => {
              router.push("/login");
            }}
          >
            Back to Home
          </HUI.Button>
        </div>
      </GridItem>
    </GridGuidance>
  );
};
