import { Portal, PortalProps } from "@/components/portal/Portal";

export const PopupPortal = ({ children }: PortalProps) => {
  if (typeof document === "object") {
    const root = document?.getElementById("popup--portal--root");

    return <Portal root={root}>{children}</Portal>;
  } else {
    return <></>;
  }
};
