import {
  useMessageModalActionComponent,
  useMessageModalContentComponent,
  useMessageModalOpen,
  useMessageModalPressEnterAction,
  useMessageModalStoreActions,
  useMessageModalTitle,
} from "@/stores/zustand/modal/useMessageModalStore";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";
import { useEffect } from "react";
import { ModalPortal } from "./ModalPortal";

const gridRuleset: HENGE.GridRuleType[] = [
  {
    minWidth: 0,
    maxWidth: 600,
    gridColumn: "1 / -1",
    gridRow: "1 / -1",
    style: { borderRadius: 0 },
  },
  {
    minWidth: 600,
    maxWidth: 1024,
    gridColumn: "4 / -4",
    gridRow: "4 / -4",
    style: { borderRadius: "8px", gridColumn: "1 / -1", gridRow: "1 / -1" },
  },
  {
    minWidth: 1024,
    maxWidth: 1440,
    gridColumn: "6 / -6",
    gridRow: "4 / -4",
    style: { borderRadius: "16px" },
  },
  {
    minWidth: 1440,
    maxWidth: Number.MAX_SAFE_INTEGER,
    gridColumn: "10 / -10",
    gridRow: "4 / -4",
    style: { borderRadius: "24px" },
  },
];

export const MessageModal = () => {
  const open = useMessageModalOpen();
  const title = useMessageModalTitle();
  const contentComponent = useMessageModalContentComponent();
  const actionComponent = useMessageModalActionComponent();
  const pressEnterAction = useMessageModalPressEnterAction();

  const { closeMessageModal } = useMessageModalStoreActions();

  const pressEsc = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      closeMessageModal();
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && pressEnterAction) {
        pressEnterAction();
      }
    };

    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, pressEnterAction]);

  useEffect(() => {
    window.addEventListener("keydown", pressEsc);
    return () => {
      window.removeEventListener("keydown", pressEsc);
    };
  }, []);

  return (
    <ModalPortal>
      <HUI.Modal
        className={
          "w-full h-full absolute top-0 left-0 flex flex-col justify-center items-center"
        }
        gridRuleset={gridRuleset}
        opener={open}
        setOpener={closeMessageModal}
        header={<></>}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            closeMessageModal();
          }
        }}
        body={
          <HUI.ModalBody className="flex w-full h-full items-center justify-center">
            <div className="absolute top-[50%] max-w-[480px] left-[50%] w-full rounded-[20px] flex flex-col justify-center items-center bg-white -translate-x-1/2 -translate-y-1/2 overflow-hidden">
              <div className="w-full p-6 flex flex-col justify-start items-start gap-4">
                {title && (
                  <HUI.Text
                    kind={HENGE.TEXT_KIND.heading3}
                    weight={HENGE.TEXT_WEIGHT.semibold}
                    lineHeight={HENGE.TEXT_LINE_HEIGHT.small}
                  >
                    {title}
                  </HUI.Text>
                )}

                {contentComponent}
              </div>
              {actionComponent && (
                <>
                  <div className="w-full h-[1px] bg-[var(--border-tertiary)]" />
                  <div className="flex flex-row w-full justify-between gap-2 p-3">
                    {actionComponent}
                  </div>
                </>
              )}
            </div>
          </HUI.ModalBody>
        }
        footer={<HUI.ModalFooter />}
      />
    </ModalPortal>
  );
};
