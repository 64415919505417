import {
  createSocketSessionStore,
  SocketSessionStoreInterface,
} from "@/stores/zustand/session/useSocketSessionStore";
import {
  createCursorStore,
  CursorStoreInterface,
} from "@/stores/zustand/useCursorStore";
import { GaiaMemberData } from "@/types/data-types";
import HUI from "@henge-inc/ui/v1_2";
import { create, StoreApi, UseBoundStore } from "zustand";

export interface GaiaMemberSessionStoreInterface {
  gaiaMember: GaiaMemberData;
  cursorStore: UseBoundStore<StoreApi<CursorStoreInterface>> | undefined;
  socketSessionStore:
    | UseBoundStore<StoreApi<SocketSessionStoreInterface>>
    | undefined;
  gaiaMemberSessionStoreActions: {
    initCursorStore: (gaiaMember: GaiaMemberData) => void;
    initSocketSessionStore: (socketSessionId: string) => void;
    terminate: () => void;
  };
}

export function createGaiaMemberSessionStore(gaiaMember: GaiaMemberData) {
  return create<GaiaMemberSessionStoreInterface>((set) => ({
    gaiaMember,
    cursorStore: undefined,
    socketSessionStore: undefined,
    gaiaMemberSessionStoreActions: {
      initCursorStore: (gaiaMember) => {
        const cursorStore = createCursorStore();
        cursorStore.getState().init({
          abbrName: HUI.abbreviateName(
            gaiaMember.metadata.familyName,
            gaiaMember.metadata.givenName,
          ),
          personalColour: gaiaMember.metadata.personalColour,
        });

        set(() => ({ cursorStore }));
      },
      initSocketSessionStore: (socketSessionId) => {
        const socketSessionStore = createSocketSessionStore();
        socketSessionStore
          .getState()
          .socketSessionStoreActions.init(socketSessionId);

        set(() => ({ socketSessionStore }));
      },
      terminate: () => {},
    },
  }));
}
