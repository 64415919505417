export const ERROR_ENUM: {
  readonly NOT_FOUND: "NOT_FOUND";
  readonly INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR";
  readonly SERVICE_UNAVAILABLE: "SERVICE_UNAVAILABLE";
  readonly UNKNOWN: "UNKNOWN";
} = {
  NOT_FOUND: "NOT_FOUND",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  SERVICE_UNAVAILABLE: "SERVICE_UNAVAILABLE",
  UNKNOWN: "UNKNOWN",
};
export type ErrorEnumType = keyof typeof ERROR_ENUM;

interface ErrorInfoType {
  name: ErrorEnumType;
  code: number;
  emoji: string;
  title: string[];
  description: string;
}
export const ERROR_INFO: { [K in ErrorEnumType]: ErrorInfoType } = {
  NOT_FOUND: {
    name: "NOT_FOUND",
    code: 404,
    emoji: "⚠",
    title: ["Oops! Page Not Found."],
    description:
      "The page that you request does not exist or might have been moved.",
  },
  INTERNAL_SERVER_ERROR: {
    name: "INTERNAL_SERVER_ERROR",
    code: 500,
    emoji: "⚠️",
    title: ["Sorry, something went wrong..."],
    description: "Please try again in few minutes.",
  },
  SERVICE_UNAVAILABLE: {
    name: "SERVICE_UNAVAILABLE",
    code: 503,
    emoji: "🛠️",
    title: ["Temporarily, Henge is unavailable."],
    description:
      "The page that you request does not exist or might have been moved.",
  },
  UNKNOWN: {
    name: "UNKNOWN",
    code: 999,
    emoji: "⚠",
    title: [
      "I am Groot.",
      "Don't panic. It's temporary.",
      "We are working on it.",
    ],
    description: "Please try again in few minutes.",
  },
};
