import { create } from "zustand";

interface MessageModalState {
  open: boolean;
  title?: string;
  contentComponent?: React.ReactNode;
  actionComponent?: React.ReactNode;
  pressEnterAction?: () => void;

  messageModalStoreActions: {
    setMessageModalContents: ({
      title,
      contentComponent,
      actionComponent,
      pressEnterAction,
    }: {
      title: string;
      contentComponent: React.ReactNode;
      actionComponent?: React.ReactNode;
      pressEnterAction?: () => void;
    }) => void;
    openMessageModal: () => void;
    closeMessageModal: () => void;
  };
}

const useMessageModalStore = create<MessageModalState>((set) => ({
  open: false,
  title: "",
  contentComponent: undefined,
  actionComponent: undefined,
  pressEnterAction: undefined,
  messageModalStoreActions: {
    setMessageModalContents: ({
      title,
      contentComponent,
      actionComponent,
      pressEnterAction,
    }) =>
      set({
        title,
        contentComponent,
        actionComponent,
        pressEnterAction,
      }),
    openMessageModal: () => set(() => ({ open: true })),
    closeMessageModal: () => set(() => ({ open: false })),
  },
}));

export default useMessageModalStore;
export const useMessageModalOpen = () =>
  useMessageModalStore((state) => state.open);
export const useMessageModalTitle = () =>
  useMessageModalStore((state) => state.title);
export const useMessageModalContentComponent = () =>
  useMessageModalStore((state) => state.contentComponent);
export const useMessageModalActionComponent = () =>
  useMessageModalStore((state) => state.actionComponent);
export const useMessageModalStoreActions = () =>
  useMessageModalStore((state) => state.messageModalStoreActions);

export const useMessageModalPressEnterAction = () =>
  useMessageModalStore((state) => state.pressEnterAction);
