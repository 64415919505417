import { css } from "@emotion/react";

interface GridGuidanceProps {
  children?: React.ReactNode;
}

export const GridGuidance = ({ children }: GridGuidanceProps) => {
  return (
    <div
      className={"grid__wrapper"}
      css={css`
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        & > * {
          pointer-events: auto;
        }
      `}
    >
      {children}
    </div>
  );
};
