// import { HomePageButton } from "@/components/buttons/HomePageButton";
// import { css } from "@emotion/react";
// import { useRouter } from "next/router";
import { useRef } from "react";

const Header = () => {
  const headerRef = useRef<HTMLHeadElement>(null!);
  // const router = useRouter();

  return (
    <header
      ref={headerRef}
      className={"fixed top-0 left-0 w-full flex justify-start z-[9999]"}
    >
      {/*<div*/}
      {/*  css={css`*/}
      {/*    display: flex;*/}
      {/*    flex-flow: row nowrap;*/}
      {/*    place-content: flex-start;*/}
      {/*    align-items: center;*/}
      {/*    width: 100%;*/}
      {/*    height: 100%;*/}
      {/*  `}*/}
      {/*>*/}
      {/*  <HomePageButton />*/}
      {/*</div>*/}
      {/* <TopCapsule /> */}
      {/* <div
        css={css`
          display: flex;
          flex-flow: row nowrap;
          place-content: flex-start;
          align-items: center;
          width: 100%;
          height: 100%;
        `}
      >
        <HomePageButton />
      </div> */}
    </header>
  );
};

export default Header;
