import { HengeAuthTokens } from "@/classes/auth/Auth";

export type OptionalQueryParams<Args> =
  Extract<Args, { queryParams: object }> extends never
    ? { queryParams?: never }
    : Extract<Args, { queryParams: Record<string, any> }>;
export type OptionalReqJson<Args> =
  Extract<Args, { json: object }> extends never
    ? { json?: never }
    : Extract<Args, { json: object }>;

export type MakeFetchInitOptionsParameters<Args> = {
  path: string;
  init?: RequestInit;
} & OptionalQueryParams<Args> &
  OptionalReqJson<Args>;
export type MakeFetchInitOptionsCallback<Args> = (
  args: MakeFetchInitOptionsParameters<Args>,
) => FetchInitOptions;

export interface FetchInitOptions {
  path: string;
  init: RequestInit;
}

export const makeFetchInits = <Args>(
  ...args: Parameters<MakeFetchInitOptionsCallback<Args>>
): ReturnType<MakeFetchInitOptionsCallback<Args>> => {
  const callback: MakeFetchInitOptionsCallback<Args> = (args) => {
    const { path, queryParams, init = {}, json } = args;

    let queryParamsString: string | null = null;
    if (queryParams) {
      queryParamsString = new URLSearchParams(queryParams).toString();
    }

    if (json) {
      init.headers = { "Content-Type": "application/json", ...init.headers };
      init.body = JSON.stringify(json);
    }

    return {
      path: `${path}${queryParamsString ? `?${queryParamsString}` : ""}`,
      init: {
        ...init,
      },
    };
  };

  return callback(...args);
};

export type OptionalFetchInitParameters<Args> = OptionalQueryParams<Args> &
  OptionalReqJson<Args>;

export type FetchWithNoAuthCallback<
  Res,
  Args = { queryParams: undefined; json: undefined },
> = (args: OptionalFetchInitParameters<Args>) => Promise<Res>;

export type FetchWithHengeAuthCallback<
  Res,
  Args = { queryParams: undefined; json: undefined },
> = (
  args: OptionalFetchInitParameters<Args>,
  authTokens: HengeAuthTokens | undefined,
) => Promise<Res>;
