import {
  FetchInitOptions,
  MakeFetchInitOptionsParameters,
  makeFetchInits,
} from "@/utils/api/fetch-types";

const fetchWithNoAuth: {
  [Method in "GET" | "DELETE" | "POST" | "PUT"]: <
    Res,
    Args = { queryParams: undefined; json: undefined },
  >(
    args: MakeFetchInitOptionsParameters<Args>,
  ) => Promise<Res>;
} = {
  GET: (...args) => fetchWithNoAuthTokens("GET", ...args),
  DELETE: (...args) => fetchWithNoAuthTokens("DELETE", ...args),
  POST: (...args) => fetchWithNoAuthTokens("POST", ...args),
  PUT: (...args) => fetchWithNoAuthTokens("PUT", ...args),
};

export default fetchWithNoAuth;

async function fetchWithNoAuthTokens<Res, Args>(
  method: "GET" | "DELETE" | "POST" | "PUT",
  args: MakeFetchInitOptionsParameters<Args>,
) {
  const fetchInitOptions: FetchInitOptions = makeFetchInits<Args>({
    ...args,
  });

  const requestToFetch = makeFetch<Res>(method, fetchInitOptions);

  return requestToFetch();
}

type FetchCallback<Res> = () => Promise<Res>;
function makeFetch<Res>(
  method: "GET" | "DELETE" | "POST" | "PUT",
  fetchInitOptions: FetchInitOptions,
): FetchCallback<Res> {
  return async () => {
    // Make a fetch request to the specified path with the provided or refreshed access token
    return fetch(
      `${typeof window === "undefined" ? process.env.FETCH_SSR_BASE_URL : process.env.FETCH_CSR_BASE_URL}${fetchInitOptions.path}`,
      {
        ...fetchInitOptions.init,
        method,
      },
    ).then((res) => res.json());
  };
}
