// Henge Inc.
import HUI from "@henge-inc/ui/v1_2";
// css
import Layout from "@/components/Layout";
import { PopupError } from "@/components/popup/error/PopupError";
import {
  useGlobalActions,
  usePopupError,
} from "@/stores/zustand/useGlobalStore";
import { NextPage } from "next";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import Head from "next/head";
import "overlayscrollbars/overlayscrollbars.css";
import React, { useEffect } from "react";
import "./_app.css";
// import ErrorBoundary from "@/components/err/ErrorBoundary";
import { PortalRoot } from "@/components/portal/PortalRoot";
import { SessionProvider } from "next-auth/react";
import { useRouter } from "next/router";
// import { appWithTranslation } from "next-i18next";
import { GloballyNextAuthSession } from "@/components/auth/GloballyNextAuthSession";
import ErrorBoundary from "@/components/error/ErrorBoundary";
import { MessageModal } from "@/components/modal/MessageModal";
import { enableMapSet } from "immer";

enableMapSet();

const isProd =
  process.env.NODE_ENV === "production" && process.env.BUILD_MODE === "prod";
// const isStage = process.env.NODE_ENV === "production" && process.env.BUILD_MODE === "stage";

export const SITE_TITLE = "Henge - Engine for Design";

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (!isProd) return;
  if (typeof window === "undefined") return;
  if (typeof window.gtag !== "function") return;

  window.gtag("event", `NextWebVitals - ${metric.name}`, {
    value: Math.round(
      metric.name === "CLS" ? metric.value * 1000 : metric.value,
    ), // values must be integers
    event_label: metric.id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}

interface CustomAppProps extends AppProps {
  Component: NextPage & {
    getTitle?: string;
    getLayout?: (page: React.ReactElement) => React.ReactNode;
  };
}

const MyApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: CustomAppProps) => {
  const router = useRouter();
  useEffect(() => {
    if (!isProd) return;

    const routeChangeHandler = (url: string) => {
      window.gtag("config", `${process.env.GA_TAG_ID}`, {
        page_path: url,
      });
    };

    router.events.on("routeChangeComplete", routeChangeHandler);

    return () => {
      router.events.off("routeChangeComplete", routeChangeHandler);
    };
  }, [router.events]);

  const { isShowingPopupError, popupErrorMessageList } = usePopupError();
  const { resetPopupError } = useGlobalActions();

  const getTitle = Component.getTitle || SITE_TITLE;
  const getLayout: CustomAppProps["Component"]["getLayout"] =
    Component.getLayout || ((page: React.ReactNode) => <Layout>{page}</Layout>);

  return (
    <SessionProvider session={session}>
      <GloballyNextAuthSession />

      <Head>
        <title>{getTitle}</title>
      </Head>

      <HUI.GlobalStyle />

      {/*<CURSOR.CursorElementImpl*/}
      {/*  cursorStore={useCursorStore}*/}
      {/*  memberMetadata={null}*/}
      {/*  size={HENGE.BASIC_SIZE.medium}*/}
      {/*  online={true}*/}
      {/*/>*/}

      <PopupError
        handle={isShowingPopupError}
        resetHandler={resetPopupError}
        messageList={popupErrorMessageList}
      />

      {getLayout(
        <>
          <ErrorBoundary>
            {/*<NextIntlClientProvider*/}
            {/*  locale={router.locale}*/}
            {/*  messages={pageProps.messages}*/}
            {/*>*/}
            <Component {...pageProps} />
            {/*</NextIntlClientProvider>*/}
          </ErrorBoundary>
        </>,
      )}

      <PortalRoot id={"rosetta--portal--root"} zIndex={3000} />
      <PortalRoot id={"capsule--portal--root"} zIndex={3100} />
      <PortalRoot
        id={"gaia--member--cursor--portal--root"}
        className={"grid__wrapper"}
        zIndex={3300}
      />
      <PortalRoot
        id={"popup--portal--root"}
        className={"grid__wrapper"}
        zIndex={3200}
      />
      <PortalRoot
        id={"modal--portal--root"}
        className={"grid__wrapper"}
        zIndex={3400}
      />
      <MessageModal />
      <PortalRoot id={"tutorial--portal--root"} zIndex={3500} />
      <PortalRoot
        id={"overlay--feedback--portal--root"}
        className={"grid__wrapper"}
        zIndex={3600}
      />
      {/* <PortalRoot id={"banner--portal--root"} zIndex={9999} /> */}
      {/* <CookiesBanner /> */}
    </SessionProvider>
  );
};

// export default appWithTranslation(MyApp);
export default MyApp;
