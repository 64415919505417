import { create, StoreApi, UseBoundStore } from "zustand";
import { GaiaMemberSessionStoreInterface } from "@/stores/zustand/gaia-member/useGaiaMemberSessionStore";
import { GaiaMemberData } from "@/types/data-types";
import { SessionCurrentLocation } from "@/stores/zustand/session/useSocketSessionStore";
import { createGaiaMemberSessionStore } from "@/stores/zustand/gaia-member/useGaiaMemberSessionStore";
import _ from "lodash";

export interface GaiaMemberSessionContainerInterface {
  /**
   * Map<socketSessionId, UseBoundStore<StoreApi<CursorStoreInterface>>>
   * */
  gaiaMemberSessionMap: Map<
    string,
    UseBoundStore<StoreApi<GaiaMemberSessionStoreInterface>>
  >;
  gaiaMemberSessionContainerActions: {
    add: (
      socketSessionId: string,
      gaiaMember: GaiaMemberData,
    ) => UseBoundStore<StoreApi<GaiaMemberSessionStoreInterface>>;
    findBySocketSessionId: (
      socketSessionId: string,
    ) => UseBoundStore<StoreApi<GaiaMemberSessionStoreInterface>> | undefined;
    findAllByMemberId: (
      memberId: number,
    ) => UseBoundStore<StoreApi<GaiaMemberSessionStoreInterface>>[];
    findAllByCurrentLocation: (
      currentLocation: SessionCurrentLocation,
    ) => UseBoundStore<StoreApi<GaiaMemberSessionStoreInterface>>[];
    remove: (socketSessionId: string) => void;
  };
}

export function createGaiaMemberSessionContainer() {
  return create<GaiaMemberSessionContainerInterface>((set, get) => ({
    gaiaMemberSessionMap: new Map(),
    gaiaMemberSessionContainerActions: {
      add: (socketSessionId, gaiaMember) => {
        const newGaiaMemberSessionStore =
          createGaiaMemberSessionStore(gaiaMember);
        newGaiaMemberSessionStore
          .getState()
          .gaiaMemberSessionStoreActions.initCursorStore(gaiaMember);
        newGaiaMemberSessionStore
          .getState()
          .gaiaMemberSessionStoreActions.initSocketSessionStore(
            socketSessionId,
          );

        /**
         * TODO : update cursor by socket
         * @see {@/engine/gaia/gaiaSocketEvent.ts} - function initGaiaMemberSessionStore
         * */

        const newMap = new Map(get().gaiaMemberSessionMap);
        newMap.set(socketSessionId, newGaiaMemberSessionStore);

        set(() => ({
          gaiaMemberSessionMap: newMap,
        }));

        return newGaiaMemberSessionStore;
      },
      findBySocketSessionId: (socketSessionId) => {
        const findGaiaMemberSession =
          get().gaiaMemberSessionMap.get(socketSessionId);

        return findGaiaMemberSession;
      },
      findAllByMemberId: (memberId) => {
        const findAllGaiaMemberSession: UseBoundStore<
          StoreApi<GaiaMemberSessionStoreInterface>
        >[] = [];

        get().gaiaMemberSessionMap.forEach((gaiaMemberSession) => {
          if (
            memberId === gaiaMemberSession.getState().gaiaMember.metadata.id
          ) {
            findAllGaiaMemberSession.push(gaiaMemberSession);
          }
        });

        return findAllGaiaMemberSession;
      },
      findAllByCurrentLocation: (currentLocation) => {
        const findAllGaiaMemberSession: UseBoundStore<
          StoreApi<GaiaMemberSessionStoreInterface>
        >[] = [];

        get().gaiaMemberSessionMap.forEach((gaiaMemberSession) => {
          if (
            _.isEqual(
              currentLocation,
              gaiaMemberSession.getState().socketSessionStore?.getState()
                .currentLocation,
            )
          ) {
            findAllGaiaMemberSession.push(gaiaMemberSession);
          }
        });

        return findAllGaiaMemberSession;
      },
      remove: (socketSessionId) => {
        const newMap = new Map(get().gaiaMemberSessionMap);
        newMap.delete(socketSessionId);

        set(() => ({
          gaiaMemberSessionMap: newMap,
        }));
      },
    },
  }));
}
